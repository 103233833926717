import { makeStyles } from "@mui/styles";

export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0px",
    position: "absolute",
    background: "linear-gradient(0deg, rgba(103, 80, 164, 0.11), rgba(103, 80, 164, 0.11)), #FFFBFE;",
    borderRadius: "28px",
    width: "30%",
    height: "auto",
    outline: 0,
    "@media (max-width: 1280px)": {
      width: "40%"
    },
    "@media (max-width: 912px)": {
      width: "55%"
    },
    "@media (max-width: 650px)": {
      width: "90%"
    }
  },
  form: {
    width: "100%"
  },
  saunaIdModal: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 15px",
    "@media (max-width: 500px)": {
      margin: "20px 10px"
    }
  },
  footer: {
    margin: "-10px 0px 20px 0px"
  },
  delete: {
    display: "flex",
    justifyContent: "center",
    cursor: 'default'
  },
  imagediv: {
    padding: '10px 0px',
    margin: '20px 0px 30px 0px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  imageComponent: {
    width: "300px",
    maxWidth: "300px",
    "@media (max-width: 300px)": {
      width: "200px",
      maxWidth: "200px",
    }
  }
}));
