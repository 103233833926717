import * as React from "react";
import { Card, CardContent } from '@mui/material';
import { useAuthState, Loading } from 'react-admin';
import { Navigate } from 'react-router-dom';

export const VerifyUser = () => {
     const { isLoading, authenticated } = useAuthState();
     if (isLoading) {
          console.log("authenticated loading")
          return <Loading />;
     }
     if (authenticated) {
          return <Navigate to='/customers' />
     }
     return <Card><CardContent></CardContent></Card>;
}