import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  productData: {
    border: "1px solid #F4772F",
    borderRadius: "10px",
    margin: "2% 2% 2% 2%",
  },
  product: {
    margin: "1% 2% 2% 6%",
    display: "flex",
  },
  partition: {
    marginLeft: "2%",
  },
  mainDiv: {
    border: "1px solid #F4772F",
    borderRadius: "10px",
    margin: '10px',
    "@media (max-width: 550px)": {
      margin: '10px',
    },
    "@media (max-width: 345px)": {
      margin: '40px 0px'
    }
  },
  text: {
    fontSize: "16px",
    color: "grey",
    fontWeight: 400,
  },
  textData: {
    fontSize: "16px",
    color: "dimgray",
    fontWeight: "bold",
  },
  table: {
    border: "1px solid #F4772F",
    borderRadius: "10px",
    margin: "2% 2% 2% 2%",
  },
  details: {
    margin: "2% 2% 0% 2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 550px)": {
      margin: "4% 4% 0% 4%",
      "& h4": {
        fontSize: '14px'
      }
    },
    "@media (max-width: 300px)": {
      flexDirection: 'column',
      alignItems: "stretch",
    },
    "& h4": {
      margin: "0px",
    },
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 550px)": {
      margin: "5% 0%",
    }
  },
  images: {
    margin: "2% 5% 2% 5%",
  },
  imageQc: {
    width: "175px",
    height: "150px",
  },
  cardComponentQc: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowY: "hidden",
    overflowX: "auto",
    margin: "3% 5% 3% 5%",
    "@media (max-width: 540px)": {
      margin: "5% 5% 5% 5%",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "6px",
    },
    "&::-webkit-scrollbar-track ": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "darkorange",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "b35400",
    },
  },
  component: {
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dataError: {
    margin: "5%",
    padding: "5%",
    textAlign: "center",
  },
}));
