import React from "react";
import { Line } from "react-chartjs-2";

export const LineChart = ({ chartData, label }) => {
    return (
        <Line
            data={chartData}
            options={{
                responsive: true,
                scales: {
                    x: {
                        title: {
                            display: false,
                            text: "Program Duration",
                            color: 'orange',
                            font: {
                                size: 14,
                                weight: "bold"
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Cabin Temperature(°c)",
                            color: 'red',
                            font: {
                                size: 14,
                                weight: "bold"
                            }
                        }
                    }
                },
                plugins: {
                    title: {
                        display: false,
                        text: `${label}`
                    },
                    legend: {
                        display: false,
                        position: 'top',
                        align: 'center',
                        labels: {
                            color: 'darkred',
                            font: {
                                weight: 'bold'
                            },
                        }
                    }
                }
            }}
        />
    );
}