import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    cam: {
      margin: "10px"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    cameraButton: {
      display: "flex",
      justifyContent: "center",
      width: "75%",
      color: "white",
      padding: "0.5em",
      border: "2px solid #666",
      borderColor: "#EEE #CCC #CCC #EEE",
      backgroundColor: "#F4772F",
      "&:active": {
        borderColor: "#CCC #EEE #EEE #CCC"
      },
      '& input[accept*="camera"]': {
        display: "none"
      }
    },
    camera: {
      width: "80%",
      display: "flex",
      justifyContent: "center",
      alignItems: 'flex-start',
      "@media (max-width: 599px)": {
        width: "80%"
      },
      '& svg': {
        fontSize: '1.9rem'
      }
    },
    button: {
      margin: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    imagemodal: {
      width: "100%",
      margin: "5% 0% 5% 0%",
      height: "180px"
    },
    imgcameradiv: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    crossmark: {
      position: 'absolute',
      margin: "4% 0% 0% 0%",
      right: '14%'
    }
  }));