import * as React from 'react';
import { List, Datagrid, TextField, TextInput, Pagination as RaPagination, PaginationActions as RaPaginationActions } from 'react-admin';

export const SaunaOwnerMasterList = props => {

    const PaginationActions = props => <RaPaginationActions {...props} color="primary" showFirstButton showLastButton />;

    const Pagination = props => <RaPagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} ActionsComponent={PaginationActions} sx={styles.pagination} />;

    const filters = [<TextInput sx={styles.input} fullWidth variant="outlined" label="Search" source="search" alwaysOn placeholder={"Search by email or name"} />];

    return (
        <div>
            <List {...props} title="Sauna Owner Masters" pagination={<Pagination />} actions={false} filters={filters} exporter={false} sx={styles.listMargin}>
                <Datagrid {...props} bulkActionButtons={false} sx={styles.dataGrid}>
                    <TextField source="id" />
                    <TextField source="emailId" />
                    <TextField source="itemId" />
                    <TextField source="country" />
                    <TextField source="updatedBy" />
                </Datagrid>
            </List>
        </div>
    );
}

const styles = {
    pagination: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '& button': {
            padding: '10px',
            borderRadius: '25px',
            minHeight: '30px',
            minWidth: '30px',
            fontSize: '14px',
            fontWeight: '600'
        },
        '& .MuiTablePagination-toolbar': {
            padding: '0px'
        }
    },
    input: {
        marginTop: '15px',
        marginBottom: '15px',
        "@media (max-width: 700px)": {
            marginLeft: '0px',
            marginRight: '0px'
        }
    },
    listMargin: {
        marginLeft: '10px',
        marginRight: '10px'
    },
    dataGrid: {
        '& .RaDatagrid-table': {
            tableLayout: 'fixed',
            wordBreak: 'break-word',
            borderCollapse: 'inherit',
            border: '1px solid #d1cfc9',
            borderRadius: '5px',
            "@media (max-width: 550px)": {
                border: '2px solid #d1cfc9'
            }
        },
        '& th': {
            padding: '9px',
            fontSize: '16px',
            fontWeight: '600',
            "@media (max-width: 700px)": {
                fontSize: '14px'
            }
        },
        '& td': {
            padding: '9px',
            '& a': {
                fontSize: '15px',
                fontWeight: '700',
                "@media (min-width: 700px)": {
                    width: '85px',
                    textAlign: "center",
                    fontSize: '15px',
                    fontWeight: '700',
                    padding: '5px',
                    color: "white",
                    border: "none",
                    backgroundColor: "#F4772F",
                    zIndex: 5,
                    '&:hover': {
                        backgroundColor: "#F4772F"
                    }
                }
            },
            '& svg': {
                fontSize: '20px',
                "@media (min-width: 700px)": {
                    color: 'white'
                }
            },
            '& span': {
                color: 'gray',
                fontSize: '14px',
                fontWeight: '500'
            }
        }
    }
}