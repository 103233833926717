import * as React from "react";
import './components.css';
import { Grid, Card, CardContent, Typography, Switch, Button, Modal, Tooltip } from '@mui/material';
import { Title, useNotify } from 'react-admin';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Info } from "@mui/icons-material";
import { AnalyticsContext } from "../context/analyticsAccessContext";
import { MqttTableData, LineChartPage } from "../Mqtt/pages";
import { MqttContext } from "../context/mqttContext";
import { normalizeSaunaID } from "../Utilities/utilities";

const { REACT_APP_API_URL } = process.env;

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export const EditOnboardedDetails = () => {
    const classes = useStyles();
    const [data, setData] = React.useState({});
    const notify = useNotify();
    const [loading, setloading] = React.useState(false);
    const [E4Data, setE4Data] = React.useState('');
    const [posts, setPosts] = React.useState([]);
    const [E4Error, setE4Error] = React.useState('');
    const [programs, setPrograms] = React.useState([]);
    const { id } = useParams();
    const [analytics, setAnalytics] = React.useState(false);
    const [centralMqtt, setCentralMqtt] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [openModal, setOpenModal] = React.useState(false);
    const saunaConfiguration = React.useContext(AnalyticsContext);
    const channel = `acp/${id.toLowerCase()}/logs`;
    const mqttConnection = React.useContext(MqttContext);
    const ITEMS_PER_PAGE = 200;

    const saunaId = normalizeSaunaID(id)

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleAnalyticsChange = (event) => {
        setAnalytics(event.target.checked);
    };

    const handleCentralMqttChange = (event) => {
        setCentralMqtt(event.target.checked);
    };

    const getE4Errors = async () => {
        try {
            const startIndex = 0
            let token = localStorage.getItem('employee_token');
            const request = new Request(`${REACT_APP_API_URL}/customercare/onBoardCustomers/v2/getAllErrors?saunaId=${saunaId}&startIndex=${startIndex}&limit=${ITEMS_PER_PAGE}`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            let resp = await fetch(request);
            let body = await resp.json()
            if (body.value.length === 0 && body.result == true) {
                //there are no E4 errors for this sauna id
                setE4Data(<div className={classes.noErrorOccured}>No error was recorded</div>)
            } else if (body.value.length === 0 && body.result == false) {
                //error occured while fetching data
                setE4Error(<div className={classes.errorOccured}>Error occured while fetching data</div>)
            } else {
                //normal data is coming
                let item = body.value;
                setPosts([...posts, ...item]);
            }
        } catch (e) {
            notify(`${e.message}`, { type: 'error' });
        }
    }

    const getSaunaConfig = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            const config = {
                method: "get",
                url: `${REACT_APP_API_URL}/sauna/getSaunaConfig?saunaId=${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const data = await axios(config);
            if (data.data.result !== true) {
                setAnalytics(true);
                setCentralMqtt(true);
            } else {
                setAnalytics(data.data.value.config.Enable_Analytics);
                setCentralMqtt(data.data.value.config.Enable_Central_Mqtt)
            }
        } catch (error) {
            if (error.response) {
                notify(`${error.response.data.error}`, { type: 'error' });
            } else {
                notify(`${error.message}`, { type: 'error' });
            }
        }
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        let token = localStorage.getItem('employee_token')
        const data = {
            saunaId: id,
            config: {
                Enable_Analytics: analytics,
                Enable_Central_Mqtt: centralMqtt
            }
        };
        const config = {
            method: 'post',
            url: `${REACT_APP_API_URL}/sauna/addSaunaConfig`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios(config).then(function (response) {
            getSaunaConfig();
            notify(`Succesfully updated Sauna Cofiguration for saunaId ${id}`, { type: 'success' });
            setOpenModal(false);
        }).catch(function (error) {
            if (error.response) {
                notify(`${error.response.data.error}`, { type: 'error' });
            } else {
                notify(`${error.message}`, { type: 'error' });
            }
        });
    }

    React.useEffect(() => {
        getE4Errors()
        getOnboardedInfo()
        getPrograms()
        getSaunaConfig()
        if (saunaConfiguration.config === false) {
            saunaConfiguration.saunaAccessApi()
        }
        if (mqttConnection.client === null) {
            mqttConnection.mqttConnection();
        }
        mqttConnection.mqttSubscribe(channel);
        mqttConnection.mqttMessagesReceived(channel);
        return () => {
            mqttConnection.mqttUnSubscribe(channel);
        };
    }, [mqttConnection.client]);

    const getPrograms = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            const request = new Request(`${REACT_APP_API_URL}/customercare/onBoardCustomers/v2/lastThreeProgram?saunaId=${saunaId}`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            const resp = await fetch(request);
            const body = await resp.json();
            if (resp.status === 400 || resp.status === 500) {
                //bad reqest
                throw `error occured while fetching data`
            } else {
                let lastThreeProgram = body.value;
                setPrograms(lastThreeProgram)
            }
        } catch (e) {
            notify(`${e.message}`, { type: 'error' });
        }
    }

    const getOnboardedInfo = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            const request = new Request(`${REACT_APP_API_URL}/customercare/onBoardCustomers/details`, {
                method: 'POST',
                body: JSON.stringify({ id: id }),
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            let resp = await fetch(request);
            let body = await resp.json()
            setData(body.result === false ? body : body.userData);
            setloading(true);
        } catch (e) {
            setloading(false)
            notify(`${e.message}`, { type: 'error' });
        }
    }

    const updateModalBody = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.title}>
                <h2 id="simple-modal-title">Alert</h2>
                <p id="simple-modal-description">
                    Are you sure, that you want to Save the Sauna Configuration for SaunaId: {id}?
                </p>
                <p id="simple-modal-description">
                    Note: This settings will only apply for app version 2.8.3 and above
                </p>
            </div>
            <div className={classes.modalButton}>
                <Button size="small" sx={styles.cancelButton} onClick={handleCloseModal}>
                    cancel
                </Button>
                <Button size="small" sx={styles.saveButton} onClick={onFormSubmit}>
                    Save
                </Button>
            </div>
        </div>
    );

    return (
        <div className={classes.grid}>
            {data.result !== false && loading &&
                <Grid container spacing={2} >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card variant="outlined" sx={styles.card}>
                            <Title title="onBoard Customer Details" />
                            {data ? (
                                <>
                                    <CardContent className={classes.parentContainer}>
                                        <div className={classes.dataContainer}>
                                            <div className={classes.saunaIdDiv}>
                                                <Typography className={classes.saunaIdText}><span>SaunaId:</span>{id}</Typography>
                                            </div>
                                            {Object.keys(data).map((item, index) => {
                                                if (data[item]) {
                                                    if (!Array.isArray(data[item])) {
                                                        if (typeof data[item] !== 'object' && data[item] !== null) {
                                                            return (
                                                                <div className={classes.row}>
                                                                    <span className={classes.listHeader}>{Object.keys(data)[index]}:</span>
                                                                    <span className={classes.listValue}>{data[item]}</span>
                                                                </div>
                                                            )
                                                        }
                                                        else if (typeof data[item] === 'object' && data[item] !== null) {
                                                            let reactChildElements = [];
                                                            Object.keys(data[item]).map((objKey, i) => {
                                                                reactChildElements.push(
                                                                    <div className={classes.row}>
                                                                        <span className={classes.listHeader}>{objKey}:</span>
                                                                        <span className={classes.listValue}>{data[item][objKey]}</span>
                                                                    </div>
                                                                )
                                                            })
                                                            return reactChildElements
                                                        }
                                                    } else {
                                                        //if array ctrl comes here
                                                        return null
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                        <div id="scrollableDiv" className={classes.msgContainer} >
                                            <div className={classes.eventTimeline}>Overview</div>
                                            <div className={classes.myPrograms}>
                                                <div className={classes.currentProgram}>
                                                    <div className={classes.programField}>
                                                        <div className={classes.programFieldItem}>Current Program </div>
                                                        {programs[0] ?
                                                            <div className={classes.programFieldValue}>{programs[0] && (programs[0].payload.programName)}</div> :
                                                            <div className={classes.itemAbsent}>NA</div>}
                                                    </div>
                                                    <div className={classes.programField}>
                                                        <div className={classes.programFieldItem}>Temperature </div>
                                                        {programs[0] ?
                                                            <div className={classes.programFieldValue}>{programs[0] && (programs[0].payload.currentTemp)} F ({moment(programs[0]?.acp_date, "YYYYMMDD h:mm:ss").fromNow()})</div> :
                                                            <div className={classes.itemAbsent}>NA</div>}
                                                    </div>
                                                    <div className={classes.programField}>
                                                        <div className={classes.programFieldItem}>Target Temperature</div>
                                                        {programs[0] ?
                                                            <div className={classes.programFieldValue}>{programs[0] && (programs[0].payload.setTemp)} F</div> :
                                                            <div className={classes.itemAbsent}>NA</div>}
                                                    </div>
                                                    <div className={classes.programField}>
                                                        <div className={classes.programFieldItem}>Android Version on ACP</div>
                                                        {programs[0] ?
                                                            <div className={classes.programFieldValue}>{programs[0] && (programs[0].os_version)}</div> :
                                                            <div className={classes.itemAbsent}>NA</div>}
                                                    </div>
                                                    <div className={classes.programField}>
                                                        <div className={classes.programFieldItem}>Vista App Version</div>
                                                        {programs[0] ?
                                                            <div className={classes.programFieldValue}>{programs[0] && (programs[0].app_version)}</div> :
                                                            <div className={classes.itemAbsent}>NA</div>}
                                                    </div>
                                                </div>
                                                {programs.length ?
                                                    <div>
                                                        <div className={classes.lastThreeProgram}>Last Three Program:</div>
                                                        <div className={classes.programNames}>
                                                            <div className={classes.programItemsNameFeild}>Name</div>
                                                            <div className={classes.programItemsTime}>Started At</div>
                                                        </div>
                                                        {programs ?
                                                            programs.map((program) =>
                                                                <div className={classes.programNames}>
                                                                    <div className={classes.singlePrograme}>{program.payload.programName}</div>
                                                                    <div className={classes.singleProgrameDate}>{moment(program?.acp_date).format('Do MMMM YYYY')}</div>
                                                                </div>
                                                            ) :
                                                            <div className={classes.itemAbsent}>NA</div>}
                                                    </div> :
                                                    ''}
                                            </div>
                                            <div className={classes.eventTimeline}>Event Timeline</div>
                                            <InfiniteScroll
                                                className={classes.infinteScroll}
                                                dataLength={posts.length}
                                                loader={<h4 style={{ textAlign: 'center', margin: '20px 15px', width: 'max-content' }}>Loading...</h4>}
                                                endMessage={E4Data === '' && E4Error === '' && posts.length >= 1 && posts.length <= 200 && (
                                                    <p style={{ textAlign: 'center', margin: '20px 15px', width: 'max-content' }}>
                                                        <b>Yay! You have seen it all</b>
                                                    </p>)
                                                }
                                                scrollableTarget="scrollableDiv"
                                            >
                                                {posts.length >= 1 && posts.map((post, index) => (
                                                    <div className={classes.cardDiv}>
                                                        <Timeline className={classes.timelineStyle} align="left">
                                                            <TimelineItem>
                                                                <TimelineSeparator className={classes.timelineSeperator}>
                                                                    <TimelineDot sx={styles.timelineDot} />
                                                                    <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={styles.timelineContent}>
                                                                    <div className={classes.divDate}>
                                                                        <span className={classes.date}>{moment(post?.acp_date).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                                                        <span> ({moment(post?.acp_date, "YYYYMMDD, h:mm:ss").fromNow()})</span>
                                                                    </div>
                                                                    <div className={classes.errorArea}>
                                                                        <div className={classes.messgType}>
                                                                            <p className={classes.mssgTypeKey}>Message Type</p>
                                                                            <p className={classes.mssgTypeValue}>{post.payload.errorTitle}</p>
                                                                        </div>
                                                                        <div className={classes.descriptionBody}>
                                                                            <div className={classes.errorDesc}>
                                                                                <p className={classes.descKey}>Description:</p>
                                                                                <p className="descValue">{post.payload.errorDescription}</p>
                                                                            </div>
                                                                            <div className={classes.userType}>
                                                                                <p className={classes.userTypeKey}>User type:</p>
                                                                                <p className={classes.userTypeValue}>{post.useremail}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                        </Timeline>
                                                    </div>
                                                ))}
                                            </InfiniteScroll>
                                            {E4Data ? E4Data : E4Error}
                                        </div>
                                    </CardContent>
                                    <div className={classes.mqttCard}>
                                        <CardContent className={classes.cardContentSwitch}>
                                            <div className={classes.saunaConfigDiv}>
                                                <Typography className={classes.saunaConfigMainText}>Sauna Configuration: <span>{id}</span></Typography>
                                            </div>
                                            <div className={classes.analyticsDiv}>
                                                <div className={classes.divSwitch}>
                                                    <Tooltip classes={{ tooltip: classes.popperTooltip }} placement="bottom-end" title="This button toggles the analytics collected by Sunlighten to diagnose issues and enhance customer experience. Some users have reported session drop outs when this is enabled and ACP is connected to internet. Disabling analytics will stop session drop outs; But will also limit us from gathering the diagnostics data from the particular sauna.Use with caution.">
                                                        <Typography className={classes.saunaConfigText}>
                                                            Analytics
                                                            <Info className={classes.infoIconTooltip} />
                                                        </Typography>
                                                    </Tooltip>
                                                    <div className={classes.switchSubDiv}>
                                                        {analytics ? <Typography className={classes.switchText} color="green">enabled</Typography> : <Typography className={classes.switchText} color="red">disabled</Typography>}
                                                        <Switch
                                                            classes={{
                                                                track: classes.switch_track,
                                                                switchBase: classes.switch_base,
                                                                colorPrimary: classes.switch_primary,
                                                            }}
                                                            checked={analytics}
                                                            disabled={saunaConfiguration.config === false}
                                                            onChange={handleAnalyticsChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.divSwitch}>
                                                    <Typography className={classes.saunaConfigText}>
                                                        Use Central MQTT
                                                    </Typography>
                                                    <div className={classes.switchSubDiv} >
                                                        {centralMqtt ? <Typography className={classes.switchText} color="green">enabled</Typography> : <Typography className={classes.switchText} color="red">disabled</Typography>}
                                                        <Switch
                                                            classes={{
                                                                track: classes.switch_track,
                                                                switchBase: classes.switch_base,
                                                                colorPrimary: classes.switch_primary,
                                                            }}
                                                            checked={centralMqtt}
                                                            disabled={saunaConfiguration.config === false}
                                                            onChange={handleCentralMqttChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </div>
                                                </div>
                                                {saunaConfiguration.config === true ?
                                                    <div className={classes.saunaConfigButtonDiv}>
                                                        <Button variant="contained" size="medium" onClick={() => handleOpenModal()}>
                                                            Save
                                                        </Button>
                                                    </div> : null}
                                            </div>
                                            <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
                                                {updateModalBody}
                                            </Modal>
                                        </CardContent>
                                        <LineChartPage channelSubscribe={mqttConnection.channelSubscribe} payloadChartData={mqttConnection.payloadChartData} payloadDuration={mqttConnection.duration} programname={mqttConnection.programName} />
                                    </div>
                                    <CardContent className={classes.cardContentMqtt}>
                                        <div className={classes.saunaConfigDiv}>
                                            <Typography className={classes.saunaConfigMainText}>MQTT logs: <span>{id}</span></Typography>
                                        </div>
                                        <MqttTableData channelSubscribe={mqttConnection.channelSubscribe} payload={mqttConnection.payload} programname={mqttConnection.programName} />
                                    </CardContent>
                                </>
                            ) : null}
                        </Card>
                    </Grid>
                </Grid >}
        </div >
    )
};

const styles = {
    card: {
        border: '2px solid lightgrey',
        borderRadius: '5px'
    },
    timelineDot: {
        backgroundColor: "red",
        margin: '8px'
    },
    timelineContent: {
        padding: '0px'
    },
    cancelButton: {
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: "red"
        }
    },
    saveButton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: "green"
        }
    }
}

const useStyles = makeStyles((theme) => ({
    saunaIdDiv: {
        margin: '10px'
    },
    saunaIdText: {
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'center',
        '& span': {
            margin: '0px 10px',
            color: 'grey'
        }
    },
    infoIconTooltip: {
        margin: '5px',
        fontSize: '22px'
    },
    switchSubDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    switchText: {
        fontWeight: 'bold'
    },
    popperTooltip: {
        maxWidth: '50%',
        padding: '10px',
        lineHeight: '1.5',
        "@media (max-width: 550px)": {
            maxWidth: 'auto',
        }
    },
    switch_track: {
        backgroundColor: "#F32013",
    },
    switch_base: {
        color: "#F32013",
        "&.Mui-disabled": {
            color: "#A9A9A9"
        },
        "&.Mui-checked": {
            color: "#F32013"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#F32013",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#008000",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#008000",
        },
    },
    paper: {
        position: 'absolute',
        width: "40%",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid lightgrey',
        boxShadow: theme.shadows[5],
        padding: '0px',
        "@media (max-width: 1280px)": {
            width: "50%"
        },
        "@media (max-width: 912px)": {
            width: "70%"
        },
        "@media (max-width: 650px)": {
            width: "90%"
        }
    },
    title: {
        margin: '30px'
    },
    modalButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        margin: '15px',
        '& button': {
            width: 'auto',
            margin: '0px 20px',
            fontSize: "16px",
            textAlign: "center",
            color: "white",
            border: "none"
        }
    },
    grid: {
        marginTop: '0px',
        "@media (max-width: 600px)": {
            marginTop: '25px',
            marginBottom: '10px',
            marginLeft: '5px'
        },
        "@media (max-width: 300px)": {
            marginTop: '40px',
        }
    },
    parentContainer: {
        display: 'flex',
        "@media (max-width: 820px)": {
            flexDirection: 'column'
        },
        "@media (max-width: 300px)": {
            padding: '0px'
        }
    },
    infinteScroll: {
        "@media (max-width: 400px)": {
            overflow: 'unset !important',
            width: '20%'
        },
        "@media (max-width: 300px)": {
            overflow: 'unset !important',
            width: '30%'
        }
    },
    row: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: "#fafafa",
        "@media (max-width: 600px)": {
            display: 'flex',
            padding: '10px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: "#fafafa",
        },
    },
    listHeader: {
        flex: 1,
        color: 'gray',
        fontSize: '14px',
        fontWeight: '400',
        textTransform: 'capitalize',
        "@media (max-width: 600px)": {
            color: 'gray',
            fontSize: '18px',
            fontWeight: '400',
            marginTop: '7px',
            marginBottom: '7px'
        },
    },
    listValue: {
        flex: 2.25,
        fontSize: '16px',
        color: 'currentcolor',
        fontWeight: '500',
        "@media (max-width: 600px)": {
            fontSize: '20px',
            color: 'currentcolor',
            marginTop: '7px',
            marginBottom: '7px',
            fontWeight: 'bold',
        },
    },
    msgContainer: {
        margin: "20px 0px",
        height: '675px',
        overflowY: 'auto',
        width: '100%',
        border: '1px solid darkgray',
        "@media (max-width: 300px)": {
            width: 'auto',
            margin: '5px'
        }
    },
    dataContainer: {
        width: '100%',
        marginRight: '6px',
    },
    cardDiv: {
        backgroundColor: "rgb(250, 250, 250)"
    },
    errorArea: {
        width: 'fit-content',
        display: "flex",
        border: "2px red solid",
        borderRadius: "10px",
        overflow: 'auto',
        "@media (max-width: 550px)": {
            width: '100%'
        },
        "@media (max-width: 400px)": {
            width: '98%'
        }
    },
    messgType: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        whiteSpace: "pre",
        padding: "2% 5%",
        borderRight: "2px solid grey",
        "@media (max-width: 300px)": {
            justifyContent: 'center',
            padding: '10px',
            overflowWrap: 'break-word'
        }
    },
    mssgTypeKey: {
        wordBreak: "break",
        fontSize: "12px",
        margin: "0px",
    },
    mssgTypeValue: {
        fontWeight: "800",
        margin: "10px",
    },
    descriptionBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'stretch'
    },
    errorDesc: {
        display: "flex",
        flexDirection: "row",
    },
    descKey: {
        margin: "0px",
        padding: "0% 2%"
    },
    userType: {
        display: "flex",
        flexDirection: "row"
    },
    userTypeKey: {
        margin: "0px",
        padding: "0% 2%"
    },
    userTypeValue: {
        margin: "0px",
        fontWeight: "400",
        fontSize: "14px",
        marginTop: "2px"
    },
    lastThreeProgram: {
        marginLeft: '10px',
        fontSize: '13px'
    },
    timelineSeperator: {
        marginLeft: "-100%"
    },
    divDate: {
        marginTop: '2px',
        marginBottom: '10px',
        "@media (max-width: 500px)": {
            margin: '0px 50px 10px 2px'
        }
    },
    date: {
        fontWeight: "500"
    },
    noErrorOccured: {
        fontWeight: "800",
        textAlign: "center",
        marginTop: "2%"
    },
    errorOccured: {
        fontWeight: "800",
        textAlign: "center",
        marginTop: "15px",
        marginBottom: '25px',
        color: "red"
    },
    eventTimeline: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: '600',
        padding: '5px'
    },
    myPrograms: {
        border: '2px solid black',
        display: 'flex',
        borderRadius: '10px',
        margin: ' 0px 30px 0px 30px',
        flexDirection: 'column',
        padding: '1%',
        "@media (max-width: 300px)": {
            margin: '0px 10px',
            padding: '5px'
        }
    },
    programField: {
        display: 'flex',
        marginLeft: '10px',
        width: '90%',
        fontSize: '13px',
    },
    programFieldItem: {
        width: '60%',
    },
    programFieldValue: {
        fontWeight: '700',
        fontSize: '13px',
    },
    programNames: {
        display: 'flex',
        marginLeft: '10%'
    },
    programItemsNameFeild: {
        width: '50%'
    },
    singleProgrameDate: {
        fontWeight: '500',
        fontSize: '13px',
    },
    singlePrograme: {
        fontWeight: '700',
        fontSize: '13px',
        width: '50%'
    },
    itemAbsent: {
        fontSize: '13px',
        fontWeight: '700',
    },
    mqttCard: {
        display: 'flex',
        "@media (max-width: 820px)": {
            flexDirection: 'column'
        },
    },
    cardContentSwitch: {
        margin: '10px',
        border: '2px solid lightgrey',
        borderRadius: '5px',
        width: '50%',
        "@media (max-width: 820px)": {
            width: "auto",
            display: 'flex',
            flexDirection: 'column'
        },
        "@media (max-width: 300px)": {
            width: "auto",
            padding: '10px'
        }
    },
    cardContentMqtt: {
        margin: '10px',
        border: '2px solid lightgrey',
        borderRadius: '5px',
        "@media (max-width: 550px)": {
            display: 'flex',
            flexDirection: 'column'
        },
        "@media (max-width: 300px)": {
            padding: '10px'
        }
    },
    saunaConfigDiv: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center'
    },
    saunaConfigButtonDiv: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        "@media (max-width: 550px)": {
            margin: '20px 10px 0px 10px'
        }
    },
    analyticsDiv: {
        margin: '20px 0px 0px 0px',
        "@media (max-width: 550px)": {
            margin: '0px',
        }
    },
    divSwitch: {
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    saunaConfigMainText: {
        fontSize: '18px',
        fontWeight: 600,
        color: 'grey',
        textAlign: 'center',
        '& span': {
            color: 'black',
            fontWeight: 600
        }
    },
    saunaConfigText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.01rem',
        "@media (max-width: 300px)": {
            fontSize: '12px',
            fontWeight: 600,
        }
    }
}));
