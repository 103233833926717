import * as React from "react";
import { Title, useNotify } from "react-admin";
import { Grid, Button, TextField, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { compareVersions, normalizeSaunaID } from "../Utilities/utilities";

const { REACT_APP_VISTA_URL, REACT_APP_API_URL } = process.env;

export const SaunaDetails = (props) => {
  const notify = useNotify();
  const [saunaId, setSaunaId] = React.useState("");
  const [data, setData] = React.useState("");
  const [noRecords, setNoRecords] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let token = localStorage.getItem("employee_token");
    try {
      const request = new Request(
        `${REACT_APP_API_URL}/customercare/onBoardCustomers/v2/getSaunaDetails?saunaId=${saunaId}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }),
        }
      )
      await fetch(request)
        .then((response) => response.json())
        .then((data) => {
          if (data.result) {
            setData(data.value);
            const appVersion = data?.value?.appVersion || '0.0.0'; // Fallback in case appVersion is undefined
            const sauna = compareVersions(appVersion, '3.0.0') > 0 ? normalizeSaunaID(data?.value?.saunaId) : data?.value?.saunaId;
            setSaunaId(sauna);
            setVisible(true);
          } else {
            setNoRecords("no data found");
            setVisible(false);
          }
        })
        .catch((error) => {
          setNoRecords("no data found");
          setVisible(false);
          notify(`${error.message}`, { type: 'error' });
        });
    } catch (e) {
      notify(`${e.message}`, { type: 'error' });
    }
  };

  return (
    <>
      <Grid container spacing={2} className={classes.grid}>
        <Title title={"Sauna Details"} />
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={styles.searchGrid}>
          <TextField label="Search By SaunaId" size="small" variant="outlined" value={saunaId} onChange={(e) => setSaunaId(e.target.value)} />
          <Button variant="outlined" size="medium" color="primary" onClick={handleSubmit} >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={styles.dataGrid}>
          {data && visible && (
            <div className={classes.box}>
              <div className={classes.info}>
                <Typography>Sauna Info</Typography>
              </div>
              <div className={classes.saunaInfoDiv}>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>Name:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.userInfo?.fullName}</Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>EmailId:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.emailId}</Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>UserId:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.userId}</Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>SaunaId:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.saunaId}</Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>MacAddress:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.macAddress}</Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>AccountType:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.userInfo?.accountType}</Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>AppVersion:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.appVersion}</Typography>
                </div>
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>AndroidVersion:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.androidVersion}</Typography>
                </div>
                {data?.metaData?.pcbversion && <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>Protocol:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.metaData?.pcbversion}</Typography>
                </div>}
                {data?.metaData?.pcbid && <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>PcbId:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.metaData?.pcbid}</Typography>
                </div>}
                <div className={classes.dataDiv}>
                  <Typography className={classes.dataFirstText}>Country:</Typography>
                  <Typography className={classes.dataSecondText}>{data?.userInfo?.country}</Typography>
                </div>
                <div className={classes.buttonDiv}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => {
                      let modifiedSaunaId = saunaId.toLowerCase().replace('-', '')
                      window.open(
                        `${REACT_APP_VISTA_URL}/d/a89d2eee-e50f-48bb-82cc-982a1f932c25/basic-sauna-360-view?orgId=1&var-vista_email=${data?.emailId}&var-saunaid=${modifiedSaunaId}&var-device_id=${data?.metaData?.pcbid || ''}&from=now-7d`
                      )
                    }
                    }
                  >
                    See Sauna Thermal Stats (PB6 & PB7)
                  </Button>
                </div>
              </div>
            </div>
          )}
          {noRecords && !visible && (
            <div className={classes.box}>
              <Typography className={classes.saunaInfoText}>{noRecords}</Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const styles = {
  searchGrid: {
    marginTop: '10px',
    marginBottom: '10px',
    "@media (max-width: 550px)": {
      marginTop: '30px',
      marginBottom: '10px',
      marginLeft: '10px',
      display: 'flex'
    }
  },
  dataGrid: {
    marginTop: '10px',
    marginBottom: '10px'
  }
}

const useStyles = makeStyles((theme) => ({
  grid: {
    '& button': {
      marginLeft: '15px',
      width: "auto",
    }
  },
  box: {
    border: "2px solid lightgrey",
    width: "100%",
    borderRadius: 5,
    "@media (max-width: 915px)": {
      width: "100%",
    },
  },
  info: {
    '& p': {
      margin: '10px',
      fontSize: '20px',
      textAlign: "center",
      fontWeight: "bold",
    }
  },
  saunaInfoDiv: {
    margin: '10px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  dataDiv: {
    margin: '10px',
    display: "flex",
    flexDirection: "column",
  },
  buttonDiv: {
    margin: '10px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dataFirstText: {
    fontSize: '14px !important',
    color: "gray",
  },
  dataSecondText: {
    fontSize: '14px !important',
    fontWeight: "bold !important",
    color: "gray",
    marginTop: '10px !important',
  },
  button: {
    width: "75%",
    "@media (max-width: 775px)": {
      width: "100%",
    },
  },
}));