import { makeStyles } from "@mui/styles";

export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const products = [
  { label: "Mpulse", value: "Mpulse" },
  { label: "Solo", value: "Solo" },
  { label: "Solo Pad", value: "Solo Pad" },
  { label: "Solo Rise", value: "Solo Rise" },
  { label: "Signature", value: "Signature" },
  { label: "Amplify", value: "Amplify" },
];

export const styles = {
  highlightOff: {
    color: "#49454F",
    cursor: "pointer",
  },
  input: {
    width: "100%",
    borderRadius: "4px 4px 0px 0px",
    "& .MuiFilledInput-root": {
      backgroundColor: "#E7E0EC",
    },
  },
};

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    background:
      "linear-gradient(0deg, rgba(103, 80, 164, 0.11), rgba(103, 80, 164, 0.11)), #FFFBFE;",
    borderRadius: "28px",
    width: "30%",
    height: "400px",
    "@media (max-width: 1280px)": {
      width: "40%",
    },
    "@media (max-width: 912px)": {
      width: "55%",
    },
    "@media (max-width: 650px)": {
      width: "90%",
    },
  },
  form: {
    width: "100%",
    height: "100%",
  },
  saunaIdModal: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 15px",
  },
  select: {
    margin: "20px 50px",
    "@media (max-width: 500px)": {
      margin: "20px 35px",
    },
  },
  selectButton: {
    margin: "25px 0px 25px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      width: "60%",
    },
  },
  heading: {
    margin: "7px 30px",
    width: "78%",
  },
  mainDiv: {
    display: "flex",
  },
  ModalId: {
    textAlign: "center",
    color: "darkorange",
  },
}));
