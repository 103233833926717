export const getProductMake = (ID) => {
  let modalNumber = ID;
  switch (parseInt(modalNumber)) {
    case 1:
      return "Aspire";
    case 2:
      return "Believe";
    case 3:
      return "Conquer";
    case 4:
      return "Discover";
    case 5:
      return "Empower";
    case 6:
      return "Empower";
    default:
      return "Believe";
  }
};
