import * as React from "react";
import { Title, useNotify } from "react-admin";
import { useParams } from 'react-router-dom';
import { Grid, Card, CardContent, Button, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CustomerHealthinfo } from "./customerHealthinfo";
import moment from "moment";

const { REACT_APP_API_URL, REACT_APP_AUTH_URL } = process.env;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const EditCustomerDetails = () => {
  const notify = useNotify();
  const classes = useStyles();
  const [data, setData] = React.useState({});
  const [modalStyle] = React.useState(getModalStyle);
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  const { id } = useParams();
  const [visible, setVisible] = React.useState(false);

  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false);
  };

  const resetPassword = async () => {
    try {
      let token = localStorage.getItem('employee_token')
      const request = new Request(`${REACT_APP_AUTH_URL}/sunlighten/pwdReset`, {
        method: 'POST',
        body: JSON.stringify({ emailId: data.emailId }),
        headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
      });
      let resp = await fetch(request)
      let body = await resp.json()
      notify(`${body.value}`, { type: 'success' });
      handleClosePasswordModal();
    }
    catch (e) {
      notify(`${e.message}`, { type: 'error' });
      handleClosePasswordModal();
    }
  }

  const resetPasswordModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.title}>
        <h2 id="simple-modal-title">Alert</h2>
        <p id="simple-modal-description">
          Are you sure, that you want to reset the password for this customer?
        </p>
      </div>
      <div className={classes.modalButton}>
        <Button onClick={() => resetPassword()}>
          Reset Password
        </Button>
      </div>
    </div>
  );

  async function fetchData() {
    try {
      let token = localStorage.getItem('employee_token')
      const request = new Request(`${REACT_APP_API_URL}/customercare/customer/details`, {
        method: 'POST',
        body: JSON.stringify({ userId: id }),
        headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
      });
      let resp = await fetch(request)
      let body = await resp.json()
      setData(body);
      setVisible(true);
    } catch (e) {
      notify(`${e.message}`, { type: 'error' });
      setVisible(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.mainGrid}>
      {data && visible &&
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.grid}>
            <Card variant="outlined" sx={styles.card}>
              {data ? (
                <CardContent>
                  <CustomerHealthinfo title="User Info" />
                  <CustomerHealthinfo name="FullName:" value={data.fullname} />
                  <CustomerHealthinfo name="id:" value={data.id} />
                  <CustomerHealthinfo name="Emailid:" value={data.emailId} />
                  <CustomerHealthinfo name="birthdate:" value={data.birthdate} />
                  <CustomerHealthinfo name="country:" value={data.country} />
                  <div className={classes.buttonDiv}>
                    <Button variant="outlined" onClick={() => handleOpenPasswordModal()} >Reset Password</Button>
                  </div>
                </CardContent>
              ) : null}
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.grid}>
            <Card variant="outlined" sx={styles.card}>
              {data ? (
                <CardContent>
                  <CustomerHealthinfo title="Sauna Info" />
                  {data.businessInfo && Object.entries(data.businessInfo).length ?
                    Object.entries(data.businessInfo).map(([key, value]) =>
                      <CustomerHealthinfo name={`${key}:`} value={value} />)
                    : null}
                  {data.preferences && Object.entries(data.preferences).length ?
                    Object.entries(data.preferences).map(([key, value]) =>
                      <CustomerHealthinfo name={`${key}:`} value={value} />)
                    : null}
                  {data.modalOwned && Array.isArray(data.modalOwned) ? data.modalOwned.map((item) => {
                    return <CustomerHealthinfo name="onboarded SaunaId:" value={item.itemId} />
                  }) : null}
                  <CustomerHealthinfo name="accountType:" value={data.accountType} />
                </CardContent>
              ) : null}
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.grid}>
            <Card variant="outlined" sx={styles.card}>
              {data ? (
                <CardContent>
                  <CustomerHealthinfo title="Personal Info" />
                  <CustomerHealthinfo name="height:" value={data.height} />
                  <CustomerHealthinfo name="weight:" value={data.weight} />
                  <CustomerHealthinfo name="interestedHealthGoals:" value={data.interestedHealthGoals} />
                  <CustomerHealthinfo name="measurementUnits:" value={data.measurementUnits} />
                  <CustomerHealthinfo name="temperatureUnits:" value={data.temperatureUnits} />
                </CardContent>
              ) : null}
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.grid}>
            <Card variant="outlined" sx={styles.card}>
              {data ? (
                <CardContent>
                  <CustomerHealthinfo title="Date Info" />
                  <CustomerHealthinfo name="extensionOfCustom:" value={data.extensionOfCustom} />
                  <CustomerHealthinfo name="createdAt:" value={moment(data.createdAt).format('MMMM Do YYYY, h:mm:ss a')} />
                  <CustomerHealthinfo name="updatedAt:" value={moment(data.updatedAt).format('MMMM Do YYYY, h:mm:ss a')} />
                  <CustomerHealthinfo name="otp:" value={data.otp} />
                </CardContent>
              ) : null}
            </Card>
          </Grid>
        </Grid>}
      <Title title="Customer Details" />
      <Modal open={openPasswordModal} onClose={handleClosePasswordModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
        {resetPasswordModalBody}
      </Modal>
    </div>
  );
};

const styles = {
  card: {
    border: '2px solid lightgrey',
    borderRadius: '5px',
    overflow: 'auto',
    width: '100%',
    height: '340px',
    "@media (max-width: 900px)": {
      height: 'auto'
    }
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    border: '1px solid lightgrey',
    boxShadow: theme.shadows[5],
    padding: '0px',
    "@media (max-width: 1280px)": {
      width: "50%"
    },
    "@media (max-width: 912px)": {
      width: "70%"
    },
    "@media (max-width: 650px)": {
      width: "90%"
    }
  },
  title: {
    margin: '30px'
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    margin: '15px',
    '& button': {
      width: 'auto',
      fontSize: "16px",
      textAlign: "center",
      color: "white",
      border: "none",
      backgroundColor: "#f44336",
      '&:hover': {
        backgroundColor: "#ed574c"
      }
    }
  },
  mainGrid: {
    marginTop: '0px',
    "@media (max-width: 900px)": {
      marginTop: '25px',
      marginBottom: '10px',
      marginLeft: '5px'
    }
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    '& button': {
      width: 'auto',
      fontSize: "16px",
      textAlign: "center",
      color: "white",
      border: "none",
      backgroundColor: "#f44336",
      '&:hover': {
        backgroundColor: "#ed574c"
      }
    }
  }
}));