import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export const notificationToast = (msg) => {
    toast.error(msg)
}

export const notificationSuccessToast = (msg) => {
    toast.success(msg)
}