import * as React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const GlobalSelect = ({ inputLabel, open, handleClose, handleOpen, value, label, handleChange, options, variant, required }) => {
    return (
        <FormControl variant={variant} size="small" sx={{ width: '100%' }}>
            <InputLabel>{inputLabel}</InputLabel>
            <Select
                required={required}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={value}
                label={label}
                onChange={handleChange}
            >
                {options.map((item, index) => {
                    return (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}