import React from "react";
import { makeStyles } from '@mui/styles';

export const MqttTable = ({ column, rows }) => {
    const classes = useStyles();

    return (
        <div className={classes.margin}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        {column.map((item, index) => {
                            return <th key={index}>{item}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((items, index) => {
                        return (
                            <tr key={index}>
                                <td>{items.device}</td>
                                <td>{items.channel}</td>
                                <td>{items.msg.cmd}</td>
                                <td>{items.msg.state?.CabinTemperature !== undefined ? `${items.msg.state?.CabinTemperature}° c` : "null"}</td>
                                <td>{items.msg.state?.Program?.ProgramName !== undefined ? `${items.msg.state?.Program?.ProgramName}` : "null"}</td>
                                <td>{items.msg.state?.Program?.duration?.minutes !== undefined ? `${items.msg.state?.Program?.duration?.minutes} mins` : "null"}</td>
                                <td>{items.msg.state?.Program?.preheatTime !== undefined ? `${items.msg.state?.Program?.preheatTime} mins` : "null"}</td>
                                <td>{items.msg.state?.SetTemperature !== undefined ? `${items.msg.state?.SetTemperature}° c` : "null"}</td>
                                <td>{items.msg.state?.lights?.externalLight !== undefined ? `${items.msg.state?.lights?.externalLight}` : "null"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    margin: {
        overflowX: "auto",
        "@media (max-width: 700px)": {
            marginTop: "10px",
        },
        "@media (max-width: 540px)": {
            border: "1px solid #d1cfc9",
        },
        "& table": {
            borderSpacing: "0px",
            border: "1px solid darkgrey",
            width: "100%",
            "@media (max-width: 540px)": {
                border: "0px solid darkgrey",
            },
        },
        "& tr:last-child td": {
            borderBottom: "0px solid darkgrey",
        },
        "& table th": {
            margin: "0px",
            padding: "0.6rem",
            fontSize: "14px",
            borderBottom: "1px solid darkgrey",
            borderRight: "1px solid darkgrey",
            position: "relative",
        },
        "& table td": {
            margin: "0px",
            padding: "0.4rem",
            borderBottom: "1px solid darkgrey",
            borderRight: "1px solid darkgrey",
            position: "relative",
        },
        "& table tr": {
            textAlign: "center",
        },
        "& table th:last-child": {
            borderRight: "0px solid #d1cfc9",
        },
        "& table td:last-child": {
            borderRight: "0px solid #d1cfc9",
        },
    },
}));