import * as React from "react";
import { CloseSharp, Delete } from "@mui/icons-material";
import { GlobalModal } from "../../GlobalComponents";
import { useStyles, getModalStyle } from "./CreateImageModalStyles";
import { CreateQcpicsModal } from "../CreateQcpicsModal/CreateQcpicsModal";

export const CreateImageModal = ({ imageOpen, imageOnClose, src, Qcopen, QconClose, onClick, onCancelClick, onDeleteClick }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const changeModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <form className={classes.form}>
        <div className={classes.saunaIdModal}>
          <CloseSharp onClick={imageOnClose}></CloseSharp>
        </div>
        <div className={classes.imagediv}>
          <img src={src} className={classes.imageComponent} alt="img" />
        </div>
      </form>
      <div className={classes.footer}>
        <div className={classes.delete} onClick={onClick}>
          <div>
            <Delete sx={{ margin: "0px 10px 0px 0px" }} />
          </div>
          <div>Delete</div>
        </div>
      </div>
      <div>
        <CreateQcpicsModal
          Qcopen={Qcopen}
          QconClose={QconClose}
          onCancelClick={onCancelClick}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </div>
  );

  return (
    <div>
      <GlobalModal open={imageOpen} onClose={imageOnClose}>
        {changeModalBody}
      </GlobalModal>
    </div>
  );
};
