import React, { createContext, useState } from 'react';
import mqtt from "mqtt"
import { notificationToast } from '../Factory/GlobalComponents';

const { REACT_APP_MQTT_URL } = process.env;

export const MqttContext = createContext();

let payloadData = [];
let durationSet = [];

export const MqttContextProvider = (props) => {
    const [client, setClient] = useState(null)
    const [channelSubscribe, setChannelSubscribe] = useState(false);
    const [payload, setPayload] = useState([]);
    const [programName, setProgramName] = useState("");
    const [payloadChartData, setPayloadChartData] = useState([]);
    const [duration, setDuration] = useState([]);
    const token = localStorage.getItem('employee_token');

    const getClientId = () => {
        return `mqttjs_ + ${Math.random().toString(16).substr(2, 8)}`;
    };

    const mqttConnect = () => {
        const clientId = getClientId();
        const url = REACT_APP_MQTT_URL;
        const config = {
            username: '',
            password: token,
            port: 8083
        }
        const options = {
            keepalive: 60,
            clientId: clientId,
            protocolId: 'MQTT',
            protocolVersion: 5,
            clean: true,
            reconnectPeriod: 1000,
            connectTimeout: 30 * 1000,
            reschedulePings: true,
            rejectUnauthorized: false,
            ...config
        };

        const clientMqtt = mqtt.connect(url, options);

        if (clientMqtt.connected) {
            setClient(clientMqtt);
        } else {
            clientMqtt.on("connect", () => {
                setClient(clientMqtt);
            });
        }
    }

    const mqttSubscribe = (channelId) => {
        if (channelId === "") {
            notificationToast("No Channel added in for subscription")
        } else {
            client?.subscribe(channelId, { qos: 0 }, (error, granted) => {
                if (!error) {
                    setChannelSubscribe(true);
                } else {
                    return notificationToast(`MQTT Subscribe to topics error ${error}`)
                }
            });
        }
    }

    const mqttMessagesReceived = (channelId) => {
        if (channelId === "") {
            notificationToast("No Channel added in for subscription")
        } else {
            client?.on('message', (topic, message, packet) => {
                if (topic === channelId) {
                    // topic,
                    // , JSON.parse(packet.payload)
                    payloadData = [...payloadData, JSON.parse(message)];
                    const uniqueProgramData = payloadData.filter((obj) => {
                        return obj.msg.cmd === "set_startProgram" || obj.msg.cmd === "set_updateTemperature" || obj.msg.cmd === "set_internalExternalLight" || obj.msg.cmd === "set_stopProgram";
                    })
                    Object.entries(uniqueProgramData[uniqueProgramData.length - 1]).map(([key, subject], i) => {
                        if (subject?.cmd === "set_startProgram" || subject?.cmd === "set_startProgram" || subject?.cmd === "set_updateTemperature" || subject?.cmd === "set_internalExternalLight") {
                            const uniqueProgramName = Object.entries(uniqueProgramData[uniqueProgramData.length - 1]).map(([key, subject], i) => subject?.state?.Program?.ProgramName);
                            setProgramName(uniqueProgramName[2] !== undefined && uniqueProgramName[2]);
                            durationSet = [...durationSet, new Date().toLocaleTimeString()];
                            setDuration(durationSet);
                            const uniqueChart = payloadData.filter((obj) => {
                                if (JSON.stringify(obj.msg.state?.Program?.ProgramName) === JSON.stringify(uniqueProgramName[2])) {
                                    return obj.msg.cmd === "set_startProgram" || obj.msg.cmd === "set_updateTemperature" || obj.msg.cmd === "set_internalExternalLight" || obj.msg.cmd === "set_stopProgram";
                                } else {
                                    return null;
                                }
                            });
                            setPayloadChartData(uniqueChart);
                            const uniquePayload = payloadData.filter((obj, index) => {
                                if (JSON.stringify(obj.msg.state?.Program?.ProgramName) === JSON.stringify(uniqueProgramName[2])) {
                                    return index === payloadData.findIndex(o => obj.device === o.device && obj.channel === o.channel && obj.msg.cmd === o.msg.cmd && obj.msg.state?.Program?.ProgramName === o.msg.state?.Program?.ProgramName && obj.msg.state?.Program?.preheatTime === o.msg.state?.Program?.preheatTime && obj.msg.state?.SetTemperature === o.msg.state?.SetTemperature && obj.msg.state?.lights?.externalLight === o.msg.state?.lights?.externalLight);
                                } else {
                                    return null;
                                }
                            });
                            setPayload(uniquePayload);
                        }
                    })
                    Object.entries(uniqueProgramData[uniqueProgramData.length - 1]).map(([key, subject], i) => {
                        if (subject?.cmd === "set_stopProgram") {
                            durationSet = [];
                            setProgramName("");
                            setPayloadChartData([]);
                            setPayload([]);
                        }
                    })
                }
            })
        }
    }

    const mqttUnSubscribe = (channelId) => {
        if (channelId === "") {
            notificationToast("No Channel added in for subscription")
        } else {
            client?.unsubscribe(channelId, (err) => {
                if (!err) {
                    setChannelSubscribe(false);
                    setPayload([]);
                    setProgramName("");
                    setPayloadChartData([]);
                    setDuration([])
                } else {
                    console.log('Unsubscribe error', err)
                    return notificationToast(`MQTT Unsubscribe to topics error ${err}`)
                }
            });
        }
    };

    return (
        <MqttContext.Provider value={{ mqttConnection: mqttConnect, mqttSubscribe: mqttSubscribe, mqttUnSubscribe: mqttUnSubscribe, mqttMessagesReceived: mqttMessagesReceived, client: client, payload: payload, programName: programName, payloadChartData: payloadChartData, duration: duration, channelSubscribe: channelSubscribe }}>
            {props.children}
        </MqttContext.Provider>
    );
};