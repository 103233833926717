import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

export const GlobalDataTable = ({ columns, rows, pageSize, rowsPerPageOptions }) => {
    return (
        <Box sx={{ margin: '10px 0px', width: '100%' }}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-root': {
                        outline: 'none',
                        justifyContent: 'center'
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                        justifyContent: 'center'
                    },
                    '& .MuiDataGrid-cell--textLeft': {
                        justifyContent: 'center'
                    },
                    '& .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    }
                }}
                autoHeight={true}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                checkboxSelection={false}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
                sortingMode="client"
            />
        </Box>
    )
}