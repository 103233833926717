import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export const UpdateApp = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(ServiceWorker | null);

  const handleClose = () => {
    setShowReload(false);
    // create interval to update every 5 mnts
    setInterval(() => {
      if (waitingWorker !== null) {
        console.log('updated.......!')
        return setShowReload(true);
      } else {
        return setShowReload(false);
      }
    }, 300000);   // <--- this will update your application for every 5 mnts.
  }

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  React.useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        if (registration && registration.waiting) {
          setShowReload(true);
          setWaitingWorker(registration.waiting);
        }
      }
    })
  }, []);

  return (
    <Dialog
      open={showReload}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Update available"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          An update has been found. Please update the app now.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="warning">Cancel</Button>
        <Button onClick={reloadPage} color="primary">Update</Button>
      </DialogActions>
    </Dialog>
  );
};
