import { makeStyles } from "@mui/styles";

export const styles = {
  grid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "@media (max-width: 599px)": {
      justifyContent: "center"
    }
  },
  gridItemTab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  tabs: {
    width: "100%",
    margin: "0px 10px",
    "& button": {
      fontSize: "0.9rem"
    }
  },
  photo: {
    margin: "0px 20px",
    minWidth: "0px",
    width: "56px",
    height: "56px",
    borderRadius: "16px"
  },
  camera: {
    color: "white",
    fontSize: "2.3rem"
  },
  component: {
    margin: "0px 20px",
    minWidth: "0px",
    width: "56px",
    height: "56px",
    borderRadius: "16px",
    position: 'fixed',
    top: "140px",
    right: "0px"
  },
  componentQc: {
    margin: "0px 20px",
    minWidth: "0px",
    width: "56px",
    height: "56px",
    borderRadius: "16px",
    position: 'fixed',
    top: '215px',
    right: '0px',
  },
  componentQcCreate: {
    margin: "0px 20px",
    minWidth: "0px",
    width: "56px",
    height: "56px",
    borderRadius: "16px",
    position: 'fixed',
    top: '390px',
    right: '0px',
  },
  add: {
    color: "white",
    fontSize: "2.3rem"
  },
  componentType: {
    margin: "5px 0px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#1C1B1F"
  },
  componentSerialNumber: {
    margin: "5px 0px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#1C1B1F"
  }
};

export const useStyles = makeStyles((theme) => ({
  saunaId: {
    margin: '20px 0px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& span': {
      color: 'darkslategrey',
      margin: '0px 10px'
    }
  },
  heading: {
    color: "darkorange",
    textDecoration: 'none',
    fontSize: '18px',
    fontWeight: 600
  },
  divTab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowY: "hidden",
    overflowX: "auto",
    width: "100%",
    height: "175px",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "6px"
    },
    "&::-webkit-scrollbar-track ": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "darkorange",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "b35400"
    }
  },
  cardContent: {
    margin: "0px 0px 0px 10px",
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    height: "100px",
    background: "#FFFBFE",
    border: "1px solid #79747E",
    borderRadius: "15px",
    padding: "0px 0px 0px 0px",
    "@media (max-width: 350px)": {
      width: "275px"
    }
  },
  cardComponent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cardQc: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowY: "hidden",
    overflowX: "auto",
    width: "100%",
    height: "220px",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "6px"
    },
    "&::-webkit-scrollbar-track ": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "darkorange",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "b35400"
    }
  },
  cardContentQc: {
    margin: "0px 0px 0px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "170px",
    background: "#FFFBFE",
    border: "1px solid #79747E",
    borderRadius: "12px",
    padding: "0px 0px 0px 0px",
    "@media (max-width: 350px)": {
      width: "275px"
    }
  },
  cardComponentQc: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  component: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  imageComponent: {
    width: "75px",
    height: "75px",
    margin: "0px 15px"
  },
  imageQc: {
    width: "175px",
    height: "150px",
    margin: "0px 15px"
  },
  componentText: {
    margin: "10px 0px",
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0,
    width: "150px",
    "@media (max-width: 550px)": {
      margin: "10px",
      width: "116px"
    }
  },
  componentcard: {
    marginTop: '15px',
    display: "flex",
    flexWrap: "wrap"
  },
  delete: {
    display: "flex",
    justifyContent: "center"
  },
  deleteicon: {
    margin: "0px 0px 0px 0px"
  },
  addfooter: {
    position: 'fixed',
    top: '315px',
    right: '0px'
  },
  addfooterEmpty: {
    position: 'fixed',
    top: '225px',
    right: '0px'
  },
  footer: {
    position: 'fixed',
    top: '650px',
    right: '0px',
    "@media (max-width: 376px)": {
      top: "575px"
    }
  },
  footerEmpty: {
    position: 'fixed',
    top: '525px',
    right: '0px'
  },
  selectButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    margin: '30px 0px',
    '& button': {
      width: '25%',
      "@media (max-width: 820px)": {
        width: '50%'
      }
    }
  },
}));
