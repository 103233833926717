import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { CameraAlt, Add, Delete } from "@mui/icons-material";
import {
  GlobalButton,
  notificationSuccessToast,
  notificationToast,
} from "../../GlobalComponents";
import {
  CreateComponentModal,
  ImageModal,
  CreateImageModal,
  CreateQcpicsModal,
} from "../../components";
import { useStyles, styles } from "./createSaunaComponentStyles";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const CreateSaunaComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [componentType, setComponentType] = React.useState("");
  const [componentSerialNumber, setComponentSerialNumber] = React.useState("");
  const [posts, setPosts] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [imageModal, setImageModal] = React.useState(false);
  const [openCameraModal, setOpenCameraModal] = React.useState(false);
  const [openComponentCameraModal, setOpenComponentCameraModal] =
    React.useState(false);
  const saunaId = location.state.name;
  const [bigImage, setBigImage] = React.useState("");
  const [qcModal, setQcModal] = React.useState(false);
  const [componentData, setComponentData] = React.useState([]);
  const [componentId, setComponentId] = React.useState("");
  const [qcComponentModal, setQcComponentModal] = React.useState(false);
  const [componentImagePath, setComponentImagePath] = React.useState("");

  const fileSelected = (event) => {
    const [file] = event.target.files;
    setImage(window.URL.createObjectURL(file));
    setFile(event.target.files[0]);
  };

  const handleCloseCameraModal = () => {
    setOpenCameraModal(false);
    setImage("");
    setFile(null);
  };

  const handleCloseCameraComponentModal = () => {
    setOpenComponentCameraModal(false);
    setImage("");
    setFile(null);
  };

  const imageOnClick = (id) => {
    setBigImage(id);
    setImageModal(true);
  };

  const componentDeleteClick = (id) => {
    setComponentId(id);
    setQcComponentModal(true);
  };

  const submit = async (event) => {
    event.preventDefault();
    if (file === null || saunaId === "") {
      return notificationToast("SaunaId/Image is Not Selected");
    } else {
      let token = localStorage.getItem('employee_token')
      const description = saunaId.toLowerCase();
      const formData = new FormData();
      formData.append("image", file);
      formData.append("saunaId", description);
      await axios.post(`${REACT_APP_API_URL}/factory/imageUpload/images`, formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          },
        }).then(function (response) {
          notificationSuccessToast("Image Uploaded Successfully");
          setImage("");
          setFile(null);
          imageKeys();
          return JSON.stringify(response.data);
        }).catch(function (error) {
          if (error.response) {
            notificationToast(error.response.data.error);
          } else {
            notificationToast(error.message);
          }
          setImage("");
          setFile(null);
        });
      setOpenModal(false);
      setOpenCameraModal(false);
    }
  };

  const imageUploadComponent = async (event) => {
    event.preventDefault();
    if (file === null || componentSerialNumber === "") {
      return notificationToast("SaunaId/Image is Not Selected");
    } else {
      let token = localStorage.getItem('employee_token')
      const description = componentSerialNumber.toUpperCase();
      const formData = new FormData();
      formData.append("image", file);
      formData.append("saunaId", description);
      await axios.post(`${REACT_APP_API_URL}/factory/imageUpload/images`, formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          },
        }).then(function (response) {
          notificationSuccessToast("Image Uploaded Successfully");
          setImage("");
          setFile(null);
          imageKeys();
          setOpenComponentCameraModal(false);
          imagePathGenerate(response.data.imagePath);
          return JSON.stringify(response.data);
        }).catch(function (error) {
          if (error.response) {
            notificationToast(error.response.data.error);
          } else {
            notificationToast(error.message);
          }
          setImage("");
          setFile(null);
        });
    }
  };

  const imageKeys = async () => {
    let token = localStorage.getItem('employee_token')
    const config = {
      method: "get",
      url: `${REACT_APP_API_URL}/factory/imageUpload/imageList?key=${saunaId.toLowerCase() + "/"}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        const keys = response.data.value;
        photos(keys);
      })
      .catch(function (error) {
        if (error.response) {
          notificationToast(error.response.data.error);
        } else {
          notificationToast(error.message);
        }
      });
  };

  async function photos(keys) {
    try {
      const imageKeys = keys.map((item) =>
        axios.get(`${REACT_APP_API_URL}/factory/imageUpload/image?key=${item}`)
      );
      const items = await Promise.all(imageKeys);
      setPosts(items);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteImage = async (id) => {
    let token = localStorage.getItem('employee_token')
    const config = {
      method: "delete",
      url: `${REACT_APP_API_URL}/factory/imageUpload/deleteImage?key=${id.split("=")[1]}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        response.data.result === true
          ? notificationSuccessToast("Image is Deleted")
          : notificationToast("Error Image is not deleted");
        imageKeys();
        setImageModal(false);
        setQcModal(false);
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          notificationToast(error.response.data.error);
        } else {
          notificationToast(error.message);
        }
      });
  };

  const imagePathGenerate = async (item) => {
    try {
      const imageKeys = axios.get(
        `${REACT_APP_API_URL}/factory/imageUpload/image?key=${item}`
      );
      const items = await Promise.resolve(imageKeys);
      const imagePath = items.config.url.split("=")[1];
      setComponentImagePath(imagePath);
    } catch (error) {
      console.log(error);
    }
  };

  const onComponentSubmit = async () => {
    if (
      componentSerialNumber !== "" &&
      componentType !== "" &&
      componentImagePath !== "" &&
      saunaId !== ""
    ) {
      const data = {
        serialNumber: saunaId.toLowerCase(),
        componentSerialNumber: componentSerialNumber.toLowerCase(),
        componentType: componentType,
        imagePath: componentImagePath,
      };
      let token = localStorage.getItem('employee_token')
      const config = {
        method: "post",
        url: `${REACT_APP_API_URL}/factory/inventoryMasterDetails/addNewComponentInventoryMaster`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config)
        .then(function (response) {
          setComponentSerialNumber("");
          setComponentType("");
          setOpenModal(false);
          getComponentData();
          response.data.result === true
            ? notificationSuccessToast(
              "Component Data is Submitted Sucessfully"
            )
            : notificationToast("Error Component Data is Not Submitted");
          return JSON.stringify(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            notificationToast(error.response.data.error);
          } else {
            notificationToast(error.message);
          }
        });
    } else {
      return notificationToast("All Fields and Image are required to Submit");
    }
  };

  const getComponentData = async () => {
    let token = localStorage.getItem('employee_token')
    const config = {
      method: "get",
      url: `${REACT_APP_API_URL}/factory/factoryDetails/getProductInventoryMaster?search=${saunaId.toLowerCase()}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        const data =
          response.data.value &&
          response.data.value[0].ComponentInventoryMasterDetail;
        setComponentData(data);
      })
      .catch(function (error) {
        if (error.response) {
          notificationToast(error.response.data.error);
        } else {
          notificationToast(error.message);
        }
      });
  };

  const componentDeleteData = async () => {
    let token = localStorage.getItem('employee_token')
    const config = {
      method: "delete",
      url: `${REACT_APP_API_URL}/factory/inventoryMasterDetails/deleteComponentInventoryData?componentSerialNumber=${componentId.toLowerCase()}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        response.data.result === true
          ? notificationSuccessToast("Component is Deleted")
          : notificationToast("Error Component is not deleted");
        getComponentData();
        setQcComponentModal(false);
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          notificationToast(error.response.data.error);
        } else {
          notificationToast(error.message);
        }
      });
  };

  const productData = (event) => {
    event.preventDefault();
    if (componentData.length !== 0) {
      return navigate("/saunaProductsData", { state: { name: saunaId } });
    } else {
      notificationToast("provide atleast one component");
    }
  };

  React.useEffect(() => {
    if (saunaId !== "") {
      imageKeys();
      getComponentData();
    } else {
      return null;
    }
  }, [saunaId]);

  return (
    <div style={{ overflow: "hidden", width: "100%", height: "100%" }}>
      <div className={classes.saunaId}>
        <u className={classes.heading}>
          SAUNAID:<span>{saunaId}</span>
        </u>
      </div>
      <Grid container spacing={2} sx={styles.grid}>
        <Grid item xs={12} sx={styles.gridItemTab}>
          <div style={{ width: "100%", height: "auto" }}>
            <div className={classes.componentcard}>
              <div>
                <u className={classes.heading}>COMPONENTS</u>
              </div>
              {componentData.length !== 0 && componentData[0] !== null ? (
                <div className={classes.card}>
                  {componentData ? (
                    <>
                      {componentData.map((items, index) => {
                        return (
                          <div key={index} className={classes.cardContent}>
                            <div className={classes.cardComponent}>
                              <div className={classes.imagediv}>
                                <img
                                  src={`${REACT_APP_API_URL}/factory/imageUpload/image?key=${items?.imagePath}`}
                                  className={classes.imageComponent}
                                  alt="img"
                                />
                              </div>
                              <div className={classes.component}>
                                <div className={classes.componentText}>
                                  <Typography sx={styles.componentType}>
                                    {items?.componentType}
                                  </Typography>
                                  <Typography sx={styles.componentSerialNumber}>
                                    {items?.componentSerialNumber}
                                  </Typography>
                                </div>
                                <div
                                  onClick={() => {
                                    componentDeleteClick(
                                      items?.componentSerialNumber
                                    );
                                  }}
                                >
                                  <Delete
                                    sx={{ margin: "0px 10px 0px 0px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                  <div>
                    <GlobalButton
                      sx={styles.component}
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <Add sx={styles.add} />
                    </GlobalButton>
                  </div>
                </div>
              ) : (
                <div className={classes.addfooterEmpty}>
                  <GlobalButton
                    sx={styles.component}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <Add sx={styles.add} />
                  </GlobalButton>
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sx={styles.gridItemTab}>
          <div style={{ width: "100%", height: "auto" }}>
            <div className={classes.componentcard}>
              <div style={{ margin: '15px 0px' }}>
                <u className={classes.heading}>QCPICS</u>
              </div>
              {posts.length !== 0 ? (
                <div className={classes.cardQc}>
                  {posts ? (
                    <>
                      {posts.map((items, index) => {
                        return (
                          <div key={index} className={classes.cardContentQc}>
                            <div className={classes.cardComponentQc}>
                              <img
                                src={items?.config?.url}
                                className={classes.imageQc}
                                alt="img"
                                onClick={() => {
                                  imageOnClick(items?.config?.url);
                                }}
                              />
                              <div className={classes.component}></div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                  <div>
                    <GlobalButton
                      sx={componentData.length !== 0 && posts.length !== 0 ? styles.componentQcCreate : styles.componentQc}
                      onClick={() => {
                        setOpenCameraModal(true);
                      }}
                    >
                      <CameraAlt sx={styles.add} />
                    </GlobalButton>
                  </div>
                </div>
              ) : (
                <div>
                  <GlobalButton
                    sx={componentData.length !== 0 || posts.length !== 0 ? styles.componentQcCreate : styles.componentQc}
                    onClick={() => {
                      setOpenCameraModal(true);
                    }}
                  >
                    <CameraAlt sx={styles.add} />
                  </GlobalButton>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={classes.selectButton}>
        <GlobalButton onClick={productData}>Next</GlobalButton>
      </div>
      <div>
        <CreateComponentModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setComponentSerialNumber("");
            setComponentType("");
          }}
          onClickSave={onComponentSubmit}
          onClickCamera={() => {
            setOpenComponentCameraModal(true);
          }}
          openCameraModal={openComponentCameraModal}
          handleCloseCameraModal={handleCloseCameraComponentModal}
          image={image}
          disabled={file === null}
          modalClose={() => {
            setImage("");
          }}
          onChangeImage={fileSelected}
          submit={imageUploadComponent}
          componentInputValue={componentSerialNumber}
          componentSelectValue={componentType}
          componentSelecthandleChange={(event) => {
            setComponentType(event.target.value);
          }}
          componentInputhandleChange={(e) => {
            setComponentSerialNumber(e.target.value);
          }}
          handleComponentInputClick={() => {
            setComponentSerialNumber("");
          }}
        />
      </div>
      <div>
        <CreateImageModal
          Qcopen={qcModal}
          QconClose={() => {
            setQcModal(false);
          }}
          onCancelClick={() => {
            setQcModal(false);
          }}
          src={bigImage}
          imageOpen={imageModal}
          imageOnClose={() => {
            setImageModal(false);
          }}
          onDeleteClick={() => deleteImage(bigImage)}
          onClick={() => {
            setQcModal(true);
          }}
        />
      </div>
      <div>
        <ImageModal
          open={openCameraModal}
          onClose={handleCloseCameraModal}
          image={image}
          submit={submit}
          disabled={file === null}
          modalClose={() => {
            setImage("");
          }}
          onChangeImage={fileSelected}
        />
      </div>
      <div>
        <CreateQcpicsModal
          Qcopen={qcComponentModal}
          QconClose={() => {
            setQcComponentModal(false);
          }}
          onCancelClick={() => {
            setQcComponentModal(false);
          }}
          onDeleteClick={() => {
            componentDeleteData();
          }}
        />
      </div>
    </div>
  );
};
