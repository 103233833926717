import * as React from "react";
import { Title, useNotify } from "react-admin";
import { Grid, Button, TextField, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { GlobalDataTable } from "../Factory/GlobalComponents";
import moment from "moment";

const { REACT_APP_API_URL } = process.env;

export const ConnectedDevices = (props) => {
    const notify = useNotify();
    const classes = useStyles();
    const [saunaId, setSaunaId] = React.useState("");
    const [data, setData] = React.useState([]);
    const [noRecords, setNoRecords] = React.useState("");
    const [visible, setVisible] = React.useState(false);

    const ConnectedDevicesColumns = [
        { field: "id", headerName: "ID", minWidth: 100, flex: 1 },
        { field: "saunaid", headerName: "Sauna Id", minWidth: 125, flex: 1 },
        { field: "deviceid", headerName: "Device Id", minWidth: 325, flex: 1 },
        { field: "emailid", headerName: "Email Id", minWidth: 250, flex: 1 },
        {
            field: "horzionVersion",
            headerName: "Horizon Version",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.horzionVersion,
        },
        {
            field: "isAcpConnected",
            headerName: "Acp Connected",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.isAcpConnected,
        },
        {
            field: "isAppVersionMatching",
            headerName: "App Version Matching",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.isAppVersionMatching,
        },
        {
            field: "isMqttConnect",
            headerName: "Mqtt Connect",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.isMqttConnect,
        },
        {
            field: "networkConnection",
            headerName: "Network Connection",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.networkConnection,
        },
        {
            field: "platform",
            headerName: "Platform",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.platform,
        },
        {
            field: "saunaName",
            headerName: "Sauna Name",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.saunaName,
        },
        {
            field: "vistaVersion",
            headerName: "Vista Version",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.vistaVersion,
        },
        {
            field: "horizonAddress",
            headerName: "Horizon Address",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.externalIP.horizonAddress,
        },
        {
            field: "vistaAddress",
            headerName: "Vista Address",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.externalIP.vistaAddress,
        },
        {
            field: "isConnectedSameIp",
            headerName: "Connected Same Ip",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.externalIP.isConnectedSameIp,
        },
        {
            field: "horizonLatitude",
            headerName: "Horizon Latitude",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.location.horizonLatitude,
        },
        {
            field: "horizonLongitude",
            headerName: "Horizon Longitude",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.location.horizonLongitude,
        },
        {
            field: "vistaLatitude",
            headerName: "Vista Latitude",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.location.vistaLatitude,
        },
        {
            field: "vistaLongitude",
            headerName: "Vista Longitude",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.location.vistaLongitude,
        },
        {
            field: "isinRange",
            headerName: "In Range",
            minWidth: 175,
            flex: 1,
            valueGetter: (params) => params.row.metadata.location.isinRange,
        },
        { field: "createdAt", headerName: "Created At", minWidth: 225, flex: 1, valueGetter: (params) => moment(params.row.createdAt, "YYYYMMDD h:mm:ss").fromNow() },
        { field: "updatedAt", headerName: "Updated At", minWidth: 225, flex: 1, valueGetter: (params) => moment(params.row.updatedAt, "YYYYMMDD h:mm:ss").fromNow() },
    ];

    const handleSubmit = async () => {
        if (saunaId !== "") {
            let token = localStorage.getItem("employee_token");
            try {
                const request = new Request(
                    `${REACT_APP_API_URL}/customercare/connected/devices?search=${saunaId}`,
                    {
                        method: 'GET',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        }),
                    }
                )
                await fetch(request)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.result && data.value !== "no data found") {
                            let resp = data?.value?.sort((a, b) => new Date(...b.updatedAt.split('/')) - new Date(...a.updatedAt.split('/'))).slice(0, 10);
                            setData(resp);
                            setVisible(true);
                        } else {
                            setNoRecords("no data found");
                            setVisible(false);
                        }
                    })
                    .catch((error) => {
                        setNoRecords(error.message);
                        setVisible(false);
                        notify(`${error.message}`, { type: 'error' });
                    });
            } catch (e) {
                notify(`${e.message}`, { type: 'error' });
            }
        } else {
            return notify(`Please Enter SaunaId or EmailId`, { type: 'error' });
        }
    };

    return (
        <>
            <Grid container spacing={2} className={classes.grid}>
                <Title title={"Connected Devices"} />
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={styles.searchGrid}>
                    <TextField className={classes.textField} label="Search By SaunaId/EmailId" size="small" variant="outlined" value={saunaId} onChange={(e) => setSaunaId(e.target.value)} />
                    <Button type="submit" variant="outlined" size="medium" color="primary" onClick={handleSubmit}>
                        Search
                    </Button>
                </Grid>
            </Grid>
            {data && visible && data.length > 0 && (
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={styles.searchGrid}>
                        <GlobalDataTable
                            columns={ConnectedDevicesColumns}
                            rows={data}
                            rowsPerPageOptions={[5]}
                            pageSize={5}
                        />
                    </Grid>
                </Grid>
            )}
            {noRecords && !visible && (
                <div className={classes.box}>
                    <Typography align="center">{noRecords}</Typography>
                </div>
            )}
        </>
    );
};

const styles = {
    searchGrid: {
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        "@media (max-width: 550px)": {
            marginTop: '30px',
            marginBottom: '10px',
            marginLeft: '10px',
            display: 'flex'
        }
    }
}

const useStyles = makeStyles((theme) => ({
    grid: {
        '& button': {
            marginLeft: '15px',
            width: "auto",
        }
    },
    textField: {
        width: "50%",
        "@media (max-width: 550px)": {
            width: "100%",
        }
    },
    box: {
        border: "2px solid lightgrey",
        width: "100%",
        borderRadius: 5,
        padding: 5,
        "@media (max-width: 915px)": {
            width: "100%",
        },
    },
}));