import * as React from "react";
import { CloseSharp } from "@mui/icons-material";
import { GlobalButton, GlobalModal } from "../../GlobalComponents";
import { useStyles, getModalStyle } from "./CreateQcpicsModalStyles";

export const CreateQcpicsModal = ({ Qcopen, QconClose, onCancelClick, onDeleteClick }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const changeModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ width: '100%' }}>
        <div className={classes.saunaIdModal}>
          <CloseSharp onClick={QconClose}></CloseSharp>
        </div>
        <div className={classes.border}>
          <h4>Are you sure you want to delete</h4>
        </div>
        <div className={classes.buttons}>
          <div>
            <GlobalButton onClick={onCancelClick} className={classes.cancelButton} sx={{ backgroundColor: "#D3D3D3" }} >Cancel</GlobalButton>
          </div>
          <div>
            <div>
              <div>
                <GlobalButton onClick={onDeleteClick}>Delete</GlobalButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <GlobalModal open={Qcopen} onClose={QconClose}>
        {changeModalBody}
      </GlobalModal>
    </div>
  );
};
