import * as React from "react";
import { InputAdornment } from "@mui/material";
import { CameraAlt, CloseSharp, HighlightOff } from "@mui/icons-material";
import { GlobalButton, GlobalInput, GlobalModal, GlobalSelect } from "../../GlobalComponents";
import { styles, useStyles, component, getModalStyle } from "./createComponentModalStyles";
import { ImageModal } from "../ImageModal/imageModal";

export const CreateComponentModal = ({ open, onClose, onClickSave, onClickCamera, componentInputValue, componentSelectValue, componentSelecthandleChange, componentInputhandleChange, handleComponentInputClick, openCameraModal, handleCloseCameraModal, image, submit, disabled, modalClose, onChangeImage }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const changeModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <form className={classes.form} autoComplete="off">
        <div className={classes.saunaIdModal}>
          <CloseSharp onClick={onClose}></CloseSharp>
        </div>
        <div className={classes.select}>
          <GlobalSelect
            variant={"outlined"}
            options={component}
            value={componentSelectValue}
            label={"Component Type"}
            inputLabel={"Component Type"}
            handleChange={componentSelecthandleChange}
          />
        </div>
        <div className={classes.select}>
          <GlobalInput
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HighlightOff sx={styles.highlightOff} onClick={handleComponentInputClick} />
                </InputAdornment>
              ),
            }}
            size={"medium"}
            variant={"filled"}
            value={componentInputValue}
            onChange={componentInputhandleChange}
            sx={styles.input}
            label="Component Serial Number"
          />
        </div>
      </form>
      <div className={classes.footer}>
        <GlobalButton sx={styles.photo} onClick={onClickCamera}>
          <CameraAlt sx={styles.camera} />
        </GlobalButton>
        <GlobalButton sx={styles.component} onClick={onClickSave}>
          SUBMIT
        </GlobalButton>
      </div>
      <div>
        <ImageModal
          open={openCameraModal}
          onClose={handleCloseCameraModal}
          image={image}
          submit={submit}
          disabled={disabled}
          modalClose={modalClose}
          onChangeImage={onChangeImage}
        />
      </div>
    </div>
  );

  return (
    <div>
      <GlobalModal open={open} onClose={onClose}>
        {changeModalBody}
      </GlobalModal>
    </div>
  );
};