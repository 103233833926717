import * as React from "react";
import { InputAdornment } from "@mui/material";
import { CloseSharp, HighlightOff } from "@mui/icons-material";
import {
  GlobalButton,
  GlobalInput,
  GlobalSelect,
  GlobalModal,
} from "../../GlobalComponents";
import {
  styles,
  useStyles,
  products,
  getModalStyle,
} from "./createProductModalStyles";

export const CreateProductModal = ({
  open,
  onClose,
  onSubmit,
  saunaId,
  productName,
  handleProductChange,
  productDescription,
  onProductDescriptionChange,
  onProductDescriptionClose,
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const changeModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <form onSubmit={onSubmit} className={classes.form} autoComplete="off">
        <div className={classes.mainDiv}>
          <div className={classes.heading}>
            <h4>Update Product Details</h4>
          </div>
          <div className={classes.saunaIdModal}>
            <CloseSharp onClick={onClose}></CloseSharp>
          </div>
        </div>
        <div className={classes.ModalId}>
          <span>{saunaId}</span>
        </div>
        <div className={classes.select}>
          <GlobalSelect
            required={true}
            variant={"outlined"}
            options={products}
            value={productName}
            label={"Select Product"}
            inputLabel={"Select Products"}
            handleChange={handleProductChange}
          />
        </div>
        <div className={classes.select}>
          <GlobalInput
            multiline
            maxRows={3}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HighlightOff
                    sx={styles.highlightOff}
                    onClick={onProductDescriptionClose}
                  />
                </InputAdornment>
              ),
            }}
            size={"small"}
            variant={"outlined"}
            required={false}
            value={productDescription}
            onChange={onProductDescriptionChange}
            sx={styles.input}
            label="Description"
          />
        </div>
        <div className={classes.selectButton}>
          <GlobalButton type="submit">Submit</GlobalButton>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      <GlobalModal open={open} onClose={onClose}>
        {changeModalBody}
      </GlobalModal>
    </div>
  );
};
