export const normalizeSaunaID = (SaunaId, RemoveAllSpecialChars = true) => {
    SaunaId = SaunaId.toLowerCase();
    if (RemoveAllSpecialChars) {
        //return a string with all special chars removed
        return SaunaId.replace(/[^a-z0-9]+/g, "");
    } else {
        //return a string with all special chars removed expect a dash
        return SaunaId.replace(/[^a-z0-9-]+/g, "");
    }
};

// Function to parse version string into an array of integers
function parseVersion(version) {
    if (typeof version === 'string') {
        return version.split('.').map(Number);
    } else if (typeof version === 'number') {
        return [version];
    } else {
        return [0]; // Fallback in case version is neither string nor number
    }
}

// Function to compare two version strings or numbers
export function compareVersions(v1, v2) {
    const parts1 = parseVersion(v1);
    const parts2 = parseVersion(v2);

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const part1 = parts1[i] || 0;
        const part2 = parts2[i] || 0;

        if (part1 !== part2) {
            return part1 - part2;
        }
    }

    return 0; // Both versions are equal
}