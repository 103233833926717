import * as React from "react";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { GlobalDataTable } from "../Factory/GlobalComponents";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Title, useNotify } from "react-admin";
import { AnalyticsContext } from "../context/analyticsAccessContext";
import { makeStyles } from "@mui/styles";

const { REACT_APP_API_URL } = process.env;

export const SaunaConfigurationList = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [analytics, setAnalytics] = React.useState([]);
  const [centralMqtt, setCentralMqtt] = React.useState([]);
  const navigate = useNavigate();
  const saunaConfiguration = React.useContext(AnalyticsContext);

  const onEdit = (id) => {
    return navigate("/saunaConfigurationEdit", { state: { name: id } });
  };

  const AnalyticsColumns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "saunaId", headerName: "Sauna Id", width: 150 },
    {
      field: "config",
      headerName: "Analytics",
      width: 225,
      valueGetter: (params) =>
        params.row.config.Enable_Analytics === true ? "enabled" : "disabled",
    },
    { field: "updatedBy", headerName: "Updated By", width: 225 },
    {
      headerName: "",
      width: 225,
      renderCell: (cellValues) => (
        <div>
          {saunaConfiguration.config === true ? (
            <Button
              variant="contained"
              onClick={() => onEdit(cellValues.row.saunaId)}
            >
              Edit
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  const CentralMqttColumns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "saunaId", headerName: "Sauna Id", width: 150 },
    {
      field: "config",
      headerName: "Central Mqtt",
      width: 225,
      valueGetter: (params) =>
        params.row.config.Enable_Central_Mqtt === true ? "enabled" : "disabled",
    },
    { field: "updatedBy", headerName: "Updated By", width: 225 },
    {
      headerName: "",
      width: 225,
      renderCell: (cellValues) => (
        <div>
          {saunaConfiguration.config === true ? (
            <Button
              variant="contained"
              onClick={() => onEdit(cellValues.row.saunaId)}
            >
              Edit
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const getAnalyticsData = async () => {
    try {
      const sort = "id";
      const order = "ASC";
      let token = localStorage.getItem("employee_token");
      const config = {
        method: "get",
        url: `${REACT_APP_API_URL}/customercare/saunaAnalytics?_sort=${sort}&_order=${order}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await axios(config);
      const analytic = data.data.filter((items) => {
        return items.config && items.config.Enable_Analytics === false;
      });
      const centralMqt = data.data.filter((items) => {
        return items.config && items.config.Enable_Central_Mqtt === false;
      });
      tabIndex === 0 ? setAnalytics(analytic) : setCentralMqtt(centralMqt);
    } catch (error) {
      if (error.response) {
        notify(`${error.response.data.error}`, { type: "error" });
      } else {
        notify(`${error.message}`, { type: "error" });
      }
    }
  };

  React.useEffect(() => {
    getAnalyticsData();
    if (saunaConfiguration.config === false) {
      saunaConfiguration.saunaAccessApi();
    }
  }, [tabIndex]);

  return (
    <Box className={classes.box}>
      <Title title="Sauna Configuration List" />
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Analytics(disabled)" />
          <Tab label="CentralMqtt(disabled)" />
        </Tabs>
      </Box>
      <Box>
        {tabIndex === 0 && analytics.length !== 0 && (
          <GlobalDataTable
            columns={AnalyticsColumns}
            rows={analytics}
            rowsPerPageOptions={[5]}
            pageSize={5}
          />
        )}
        {tabIndex === 1 && centralMqtt.length !== 0 && (
          <GlobalDataTable
            columns={CentralMqttColumns}
            rows={centralMqtt}
            rowsPerPageOptions={[5]}
            pageSize={5}
          />
        )}
        {tabIndex === 0 && analytics.length === 0 && (
          <Typography className={classes.dataText}>
            No Users With Analytics Disabled
          </Typography>
        )}
        {tabIndex === 1 && centralMqtt.length === 0 && (
          <Typography className={classes.dataText}>
            No Users With Central Mqtt Disabled
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  box: {
    margin: "10px",
    "@media (max-width: 280px)": {
      marginTop: "30px",
    },
  },
  dataText: {
    margin: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
