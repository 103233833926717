import { makeStyles } from "@mui/styles";

export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0px",
    position: "absolute",
    background: "linear-gradient(0deg, rgba(103, 80, 164, 0.11), rgba(103, 80, 164, 0.11)), #FFFBFE;",
    borderRadius: "28px",
    width: "30%",
    height: "250px",
    outline: 0,
    "@media (max-width: 1280px)": {
      width: "40%"
    },
    "@media (max-width: 912px)": {
      width: "55%"
    },
    "@media (max-width: 650px)": {
      width: "90%"
    }
  },
  border: {
    display: "flex",
    justifyContent: "center"
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
  cancelButton: {
    backgroundColor: "#D3D3D3"
  },
  saunaIdModal: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 15px",
    "@media (max-width: 500px)": {
      margin: "20px 10px"
    }
  }
}));
