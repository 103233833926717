import * as React from "react";
import { ImageUploader } from "../ImageUploader/imageUploader";
import { CloseSharp } from "@mui/icons-material";
import { GlobalModal } from "../../GlobalComponents";
import { useStyles, getModalStyle } from './imageModalStyles'

export const ImageModal = ({ open, onClose, image, onChangeImage, submit, disabled, modalClose }) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const changeModalBody = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.saunaIdModal}>
                <CloseSharp onClick={onClose}></CloseSharp>
            </div>
            <div className={classes.modaldiv}>
                <div className={classes.imagediv}>
                    <ImageUploader
                        image={image}
                        onChangeImage={onChangeImage}
                        onSubmit={submit}
                        disabled={disabled}
                        handleCloseModal={modalClose}
                    ></ImageUploader>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <GlobalModal open={open} onClose={onClose}>
                {changeModalBody}
            </GlobalModal>
        </div>
    );
};