import * as React from "react";
import { List, Datagrid, EditButton, useNotify, TextField, TextInput, Pagination as RaPagination, PaginationActions as RaPaginationActions, } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Button, Modal } from "@mui/material";
import axios from "axios";
import { notificationSuccessToast, notificationToast } from "../Factory/GlobalComponents";

const { REACT_APP_API_URL } = process.env;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const CustomerList = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [openModal, setOpenModal] = React.useState(false);
  const [tSaunaId, setTSaunaId] = React.useState("");
  const [pSaunaId, setPSaunaId] = React.useState("");
  const [emailId, setEmailId] = React.useState("");

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEmailId("");
    setTSaunaId("");
    setPSaunaId("");
  };

  const PaginationActions = props => <RaPaginationActions {...props} color="primary" showFirstButton showLastButton />;

  const Pagination = props => <RaPagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} ActionsComponent={PaginationActions} sx={styles.pagination} />;

  const filters = [<TextInput sx={styles.input} variant="outlined" fullWidth label="Search" source="search" alwaysOn placeholder={"Search by email or name"} />];

  const changeTempSaunaToPermanentSaunaId = async () => {
    let token = localStorage.getItem("employee_token");
    if (tSaunaId === "" || pSaunaId === "" || emailId === "") {
      notify(`please fill all the fields before assigning the sauna`, { type: 'warning' });
    }
    else {
      let data = JSON.stringify({
        "emailId": emailId,
        "tempSaunaId": tSaunaId,
        "permanentSaunaId": pSaunaId
      });
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${REACT_APP_API_URL}/users/changeTempSaunaToPermanentSaunaId`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      await axios(config).then(function (response) {
        response.data.result === true
          ? notificationSuccessToast(response.data.value)
          : notificationToast(response.data.value);
        handleCloseModal();
      }).catch(function (error) {
        if (error.response) {
          notificationToast(error.response.data.error);
        } else {
          notificationToast(error.message);
        }
      });
    }
  };

  const changeModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.title}>
        <p id="simple-modal-description">
          Enter the sauna Id and  email address of the user
        </p>
        <input className={classes.input} type="text" name="tSaunaId" placeholder="Temporary Sauna Id" value={tSaunaId} onChange={e => setTSaunaId(e.target.value)} required />
        <input className={classes.input} type="text" name="pSaunaId" placeholder="Permanent Sauna Id" value={pSaunaId} onChange={e => setPSaunaId(e.target.value)} required />
        <input className={classes.input} type="email" name="email" placeholder="Email Id" value={emailId.toLowerCase()} onChange={e => setEmailId(e.target.value.toLowerCase())} required />
      </div>
      <div className={classes.modalButton}>
        <Button onClick={() => changeTempSaunaToPermanentSaunaId()}>Assign Original SaunaId</Button>
      </div>
    </div>
  );

  return (
    <div>
      {/* <div className={classes.saunaOnboard}>
        <div className={classes.changeEmailWithLabel}>
          <Button size='medium' onClick={() => handleOpenModal()}>Assign original SaunaId</Button>
        </div>
      </div> */}
      <List {...props} title="Customers" pagination={<Pagination />} actions={false} filters={filters} exporter={false} sx={styles.listMargin}>
        <Datagrid {...props} bulkActionButtons={false} sx={styles.dataGrid}>
          <TextField source="id" />
          <TextField source="fullname" />
          <TextField source="emailId" />
          <EditButton />
        </Datagrid>
      </List>
      <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
        {changeModalBody}
      </Modal>
    </div>
  );
}

const styles = {
  pagination: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& button': {
      padding: '10px',
      borderRadius: '25px',
      minHeight: '30px',
      minWidth: '30px',
      fontSize: '14px',
      fontWeight: '600'
    },
    '& .MuiTablePagination-toolbar': {
      padding: '0px'
    }
  },
  input: {
    marginTop: '15px',
    marginBottom: '15px',
    "@media (max-width: 700px)": {
      marginLeft: '0px',
      marginRight: '0px'
    }
  },
  listMargin: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  dataGrid: {
    '& .RaDatagrid-table': {
      tableLayout: 'fixed',
      wordBreak: 'break-word',
      borderCollapse: 'inherit',
      border: '1px solid #d1cfc9',
      borderRadius: '5px',
      "@media (max-width: 550px)": {
        border: '2px solid #d1cfc9'
      }
    },
    '& th': {
      padding: '9px',
      fontSize: '16px',
      fontWeight: '600',
      "@media (max-width: 700px)": {
        fontSize: '14px'
      }
    },
    '& td': {
      padding: '9px',
      '& a': {
        fontSize: '15px',
        fontWeight: '700',
        "@media (min-width: 700px)": {
          width: '85px',
          textAlign: "center",
          fontSize: '15px',
          fontWeight: '700',
          padding: '5px',
          color: "white",
          border: "none",
          backgroundColor: "#F4772F",
          zIndex: 5,
          '&:hover': {
            backgroundColor: "#F4772F"
          }
        }
      },
      '& svg': {
        fontSize: '20px',
        "@media (min-width: 700px)": {
          color: 'white'
        }
      },
      '& span': {
        color: 'gray',
        fontSize: '14px',
        fontWeight: '500'
      }
    }
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    border: '1px solid lightgrey',
    boxShadow: theme.shadows[5],
    padding: '0px',
    "@media (max-width: 1280px)": {
      width: "50%"
    },
    "@media (max-width: 912px)": {
      width: "70%"
    },
    "@media (max-width: 550px)": {
      width: "90%"
    }
  },
  title: {
    margin: '25px',
    '& p': {
      textAlign: 'center',
      margin: '10px'
    }
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    margin: '25px',
    '& button': {
      width: '100%',
      textAlign: "center",
      textDecoration: 'none',
      color: "white",
      border: "none",
      backgroundColor: "#f44336",
      '&:hover': {
        backgroundColor: "#ed574c"
      }
    }
  },
  saunaOnboard: {
    margin: '10px',
    display: 'flex',
    zIndex: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    "@media (max-width: 450px)": {
      margin: '10px',
      display: 'flex',
      zIndex: 5,
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    "@media (min-width: 700px)": {
      margin: '6px 10px 0px 10px',
      position: 'absolute',
      right: '10px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  },
  changeEmailWithLabel: {
    width: 'auto',
    height: 'auto',
    zIndex: 5,
    '& button': {
      backgroundColor: '#f44336',
      width: '100%',
      border: 'none',
      padding: '10px',
      color: 'white',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: "#ed574c"
      }
    }
  },
  input: {
    width: '100%',
    height: '35px',
    borderRadius: '5px',
    border: '1px solid darkgrey',
    textIndent: '10px',
    padding: '0px',
    margin: '10px 0px'
  }
}));