import * as React from "react";

export const CustomerHealthinfo = (props) => {
  return (
    <>
      <div className="title">
        <label className="labelTitle">{props.title}</label>
      </div>
      <div className="divItem">
        <label className="labelName">{props.name}</label>
        <label className="labelValue">{props.value}</label>
      </div>
    </>
  );
};
