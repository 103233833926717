import * as React from "react";
import { Admin, CustomRoutes, Resource, fetchUtils } from 'react-admin';
import { Route } from 'react-router-dom';
import { AccountBox, Group, LocalAtm, Equalizer, Description, Analytics, Devices } from "@mui/icons-material";
import jsonServerProvider from 'ra-data-json-server';
import { CustomerList, EditCustomerDetails, SalesList, SaunaDetails, SaunaOwnerMasterList, EditOnboardedDetails, EditSalesDetails, OnBoardedList, SaunaConfigurationList, SaunaConfigurationEdit , ConnectedDevices} from "./components";
import { EmployeeLoginPage, VerifyUser } from "./login";
import { authProvider } from './authProvider';
import { MyLayout } from './layout/layout';
import './App.css';
import { CreateSaunaProducts, SaunaProductsData, CreateSaunaComponent, ProductComponentData } from "./Factory/pages";
import { AnalyticsAccessContextProvider, MqttContextProvider } from "./context";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('employee_token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const { _DEV_ = process.env.NODE_ENV == "development" ? true : false, REACT_APP_API_URL } = process.env

const dataProvider = jsonServerProvider(`${REACT_APP_API_URL}/customercare`, httpClient);

export const App = () => {
  return (
    <AnalyticsAccessContextProvider>
      <MqttContextProvider>
        <Admin layout={MyLayout} authProvider={_DEV_ ? null : authProvider} loginPage={_DEV_ ? null : EmployeeLoginPage} dataProvider={dataProvider}>
          <CustomRoutes>
            <Route path="/verify_login" element={<VerifyUser />} />
            <Route path="/createSaunaProduct" element={<CreateSaunaProducts />} />
            <Route path="/saunaProductsData" element={<SaunaProductsData />} />
            <Route path="/createSaunaComponent" element={<CreateSaunaComponent />} />
            <Route path="/productComponentData" element={<ProductComponentData />} />
          </CustomRoutes>
          <Resource
            name="customers"
            list={CustomerList}
            edit={EditCustomerDetails}
            icon={AccountBox}
          />
          <Resource
            name="onBoardCustomers"
            list={OnBoardedList}
            edit={EditOnboardedDetails}
            icon={Group}
          />
          <Resource
            name="sales"
            list={SalesList}
            edit={EditSalesDetails}
            icon={LocalAtm}
          />
          <Resource
            name="saunaOwnerMaster"
            list={SaunaOwnerMasterList}
            edit={EditSalesDetails}
            icon={Equalizer}
          />
          <Resource
            name="saunaConfiguration"
            list={SaunaConfigurationList}
            icon={Analytics}
          />
          <Resource
            name="saunaConfigurationEdit"
            list={SaunaConfigurationEdit}
          />
          <Resource
            name="connectedDevices"
            list={ConnectedDevices}
            icon={Devices}
          />
          <Resource
            name="saunaDetails"
            list={SaunaDetails}
            icon={Description}
          />
        </Admin>
      </MqttContextProvider>
    </AnalyticsAccessContextProvider>
  )
}
