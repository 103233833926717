import * as React from 'react';
import { Button, CardContent, Modal, Switch, Tooltip, Typography } from '@mui/material';
import { Title, useNotify } from "react-admin";
import { useLocation } from "react-router-dom";
import { AnalyticsContext } from '../context/analyticsAccessContext';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { Info } from '@mui/icons-material';

const { REACT_APP_API_URL } = process.env;

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export const SaunaConfigurationEdit = () => {
    const classes = useStyles();
    const notify = useNotify();
    const location = useLocation();
    const saunaId = location.state.name;
    const [modalStyle] = React.useState(getModalStyle);
    const [openModal, setOpenModal] = React.useState(false);
    const saunaConfiguration = React.useContext(AnalyticsContext);
    const [analytics, setAnalytics] = React.useState(false);
    const [centralMqtt, setCentralMqtt] = React.useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleAnalyticsChange = (event) => {
        setAnalytics(event.target.checked);
    };

    const handleCentralMqttChange = (event) => {
        setCentralMqtt(event.target.checked);
    };

    const getSaunaConfig = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            const config = {
                method: "get",
                url: `${REACT_APP_API_URL}/sauna/getSaunaConfig?saunaId=${saunaId}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const data = await axios(config);
            if (data.data.result !== true) {
                setAnalytics(true);
                setCentralMqtt(true);
            } else {
                setAnalytics(data.data.value.config.Enable_Analytics);
                setCentralMqtt(data.data.value.config.Enable_Central_Mqtt)
            }
        } catch (error) {
            if (error.response) {
                notify(`${error.response.data.error}`, { type: 'error' });
            } else {
                notify(`${error.message}`, { type: 'error' });
            }
        }
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        let token = localStorage.getItem('employee_token')
        const data = {
            saunaId: saunaId,
            config: {
                Enable_Analytics: analytics,
                Enable_Central_Mqtt: centralMqtt
            }
        };
        const config = {
            method: 'post',
            url: `${REACT_APP_API_URL}/sauna/addSaunaConfig`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios(config).then(function (response) {
            getSaunaConfig();
            notify(`Succesfully updated Sauna Cofiguration for saunaId ${saunaId}`, { type: 'success' });
            setOpenModal(false);
        }).catch(function (error) {
            if (error.response) {
                notify(`${error.response.data.error}`, { type: 'error' });
            } else {
                notify(`${error.message}`, { type: 'error' });
            }
        });
    }

    React.useEffect(() => {
        getSaunaConfig()
        if (saunaConfiguration.config === false) {
            saunaConfiguration.saunaAccessApi()
        }
    }, []);

    const updateModalBody = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.title}>
                <h2 id="simple-modal-title">Alert</h2>
                <p id="simple-modal-description">
                    Are you sure, that you want to Save the Sauna Configuration for SaunaId: {saunaId}?
                </p>
                <p id="simple-modal-description">
                    Note: This settings will only apply for app version 2.8.3 and above
                </p>
            </div>
            <div className={classes.modalButton}>
                <Button size="small" sx={styles.cancelButton} onClick={handleCloseModal}>
                    cancel
                </Button>
                <Button size="small" sx={styles.saveButton} onClick={onFormSubmit}>
                    Save
                </Button>
            </div>
        </div>
    );

    return (
        <div className={classes.mainDiv}>
            <Title title="Sauna Configuration Edit" />
            {saunaConfiguration.config === true ?
                <CardContent className={classes.cardContentSwitch}>
                    <div className={classes.saunaConfigDiv}>
                        <Typography className={classes.saunaConfigMainText}>Sauna Configuration: <span>{saunaId}</span></Typography>
                    </div>
                    <div className={classes.analyticsDiv}>
                        <div className={classes.divSwitch}>
                            <Tooltip classes={{ tooltip: classes.popperTooltip }} placement="bottom-end" title="This button toggles the analytics collected by Sunlighten to diagnose issues and enhance customer experience. Some users have reported session drop outs when this is enabled and ACP is connected to internet. Disabling analytics will stop session drop outs; But will also limit us from gathering the diagnostics data from the particular sauna.Use with caution.">
                                <Typography className={classes.saunaConfigText}>
                                    Analytics
                                    <Info className={classes.infoIconTooltip} />
                                </Typography>
                            </Tooltip>
                            <div className={classes.switchSubDiv}>
                                {analytics ? <Typography className={classes.switchText} color="green">enabled</Typography> : <Typography className={classes.switchText} color="red">disabled</Typography>}
                                <Switch
                                    classes={{
                                        track: classes.switch_track,
                                        switchBase: classes.switch_base,
                                        colorPrimary: classes.switch_primary,
                                    }}
                                    checked={analytics}
                                    onChange={handleAnalyticsChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>
                        <div className={classes.divSwitch}>
                            <Typography className={classes.saunaConfigText}>
                                Use Central MQTT
                            </Typography>
                            <div className={classes.switchSubDiv} >
                                {centralMqtt ? <Typography className={classes.switchText} color="green">enabled</Typography> : <Typography className={classes.switchText} color="red">disabled</Typography>}
                                <Switch
                                    classes={{
                                        track: classes.switch_track,
                                        switchBase: classes.switch_base,
                                        colorPrimary: classes.switch_primary,
                                    }}
                                    checked={centralMqtt}
                                    onChange={handleCentralMqttChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>
                        <div className={classes.saunaConfigButtonDiv}>
                            <Button variant="contained" size="medium" onClick={() => handleOpenModal()}>
                                Save
                            </Button>
                        </div>
                    </div>
                </CardContent> : null}
            <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
                {updateModalBody}
            </Modal>
        </div>
    );
}

const styles = {
    cancelButton: {
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: "red"
        }
    },
    saveButton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: "green"
        }
    }
}

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        width: '50%',
        "@media (max-width: 825px)": {
            width: '75%',
        },
        "@media (max-width: 550px)": {
            width: '100%',
        },
        "@media (max-width: 280px)": {
            marginTop: '30px',
        }
    },
    saunaIdText: {
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'center',
        '& span': {
            margin: '0px 10px',
            color: 'grey'
        }
    },
    infoIconTooltip: {
        margin: '5px',
        fontSize: '22px'
    },
    switchSubDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    switchText: {
        fontWeight: 'bold'
    },
    popperTooltip: {
        maxWidth: '40%',
        padding: '10px',
        lineHeight: '1.5',
        "@media (max-width: 550px)": {
            maxWidth: 'auto',
        }
    },
    switch_track: {
        backgroundColor: "#F32013",
    },
    switch_base: {
        color: "#F32013",
        "&.Mui-disabled": {
            color: "#A9A9A9"
        },
        "&.Mui-checked": {
            color: "#F32013"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#F32013",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#008000",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#008000",
        },
    },
    paper: {
        position: 'absolute',
        width: "40%",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid lightgrey',
        boxShadow: theme.shadows[5],
        padding: '0px',
        "@media (max-width: 1280px)": {
            width: "50%"
        },
        "@media (max-width: 912px)": {
            width: "70%"
        },
        "@media (max-width: 650px)": {
            width: "90%"
        }
    },
    title: {
        margin: '30px'
    },
    modalButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        margin: '15px',
        '& button': {
            width: 'auto',
            margin: '0px 20px',
            fontSize: "16px",
            textAlign: "center",
            color: "white",
            border: "none"
        }
    },
    cardContentSwitch: {
        margin: '10px',
        border: '2px solid lightgrey',
        borderRadius: '5px',
        "@media (max-width: 550px)": {
            display: 'flex',
            flexDirection: 'column'
        },
        "@media (max-width: 300px)": {
            padding: '10px'
        }
    },
    saunaConfigDiv: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center'
    },
    saunaConfigButtonDiv: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        "@media (max-width: 550px)": {
            margin: '20px 10px 0px 10px'
        }
    },
    analyticsDiv: {
        margin: '20px 0px 0px 0px',
        "@media (max-width: 550px)": {
            margin: '0px',
        }
    },
    divSwitch: {
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    saunaConfigMainText: {
        fontSize: '18px',
        fontWeight: 600,
        color: 'grey',
        textAlign: 'center',
        '& span': {
            color: 'black',
            fontWeight: 600
        }
    },
    saunaConfigText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.01rem',
        "@media (max-width: 300px)": {
            fontSize: '12px',
            fontWeight: 600,
        }
    }
}));
