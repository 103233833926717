import * as React from "react";
import { GlobalButton } from "../../GlobalComponents";
import { CloseSharp } from "@mui/icons-material";
import { useStyles } from "./imageUploaderStyles";

export const ImageUploader = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.cam}>
      <form className={classes.form} onSubmit={props.onSubmit}>
        <label className={classes.cameraButton}>
          Take or select picture
          <input type="file" accept="image/*;capture=camera" onChange={props.onChangeImage} />
        </label>
        <div className={classes.camera}>
          {props.image && (<img id="pictureFromCamera" src={props.image} alt="img" className={classes.imagemodal} />)}
          {props.image && (<CloseSharp onClick={props.handleCloseModal} className={classes.crossmark} ></CloseSharp>)}
        </div>
        <div className={classes.button}>
          <GlobalButton disabled={props.disabled}>Submit</GlobalButton>
        </div>
      </form>
    </div>
  );
};