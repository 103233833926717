import React, { createContext, useState } from 'react';
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const AnalyticsContext = createContext();

export const AnalyticsAccessContextProvider = (props) => {
    const [saunaConfig, setSaunaConfig] = useState(false);

    const accessSaunaConfigure = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            const config = {
                method: "get",
                url: `${REACT_APP_API_URL}/sauna/fetchSaunaAccessEmails`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const data = await axios(config);
            if (data.data.result !== true) {
                setSaunaConfig(false);
            } else {
                setSaunaConfig(true)
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <AnalyticsContext.Provider value={{ config: saunaConfig, saunaAccessApi: accessSaunaConfigure }}>
            {props.children}
        </AnalyticsContext.Provider>
    );
};