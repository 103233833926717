import React from "react";
import { MqttTable } from "../../components/table";

const columns = ["Device", "Channel", "Message Command", "Cabin Temperature", "Program Name", "Program Duration", "Preheat Time", "Set Temperature", "External Lights"];

export const MqttTableData = ({ channelSubscribe, payload, programname }) => {

    return (
        <div>
            {channelSubscribe === true && payload.length > 0 && programname !== "" ?
                <MqttTable column={columns} rows={payload} /> :
                <div style={{ textAlign: 'center', color: "red", fontWeight: 600 }}>
                    <label>Make Sure This Sauna Is Running To View MQTT Live Logs</label>
                </div>}
        </div>
    );
}