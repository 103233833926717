import React from "react";
import { useTable } from "react-table";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";

export function GlobalTable({
  columns,
  data,
  visible,
  error,
  count,
  page,
  onChange,
  hideColumn,
}) {
  const classes = useStyles();
  const tableInstance = useTable({
    columns: columns,
    data: data,
    initialState: hideColumn
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div>
      {rows.length !== 0 && visible ? (
        <div className={classes.margin}>
          <table {...getTableProps()} className={classes.table}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
      {rows.length === 0 && visible && (
        <div className={classes.dataError}>
          <Typography>{error}</Typography>
        </div>
      )}
      {rows.length !== 0 && visible && (
        <div style={{ margin: "0% 5%" }}>
          <Pagination
            count={count}
            onChange={onChange}
            page={page}
            color="primary"
            className={classes.pagination}
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "3% 5% 3% 5%",
    overflowX: "auto",
    "@media (max-width: 700px)": {
      marginTop: "25px",
    },
    "@media (max-width: 540px)": {
      border: "1px solid #d1cfc9",
      margin: "5% 5% 5% 5%",
    },
    "& table": {
      borderSpacing: "0px",
      border: "1px solid darkgrey",
      width: "100%",
      "@media (max-width: 540px)": {
        border: "0px solid darkgrey",
      },
    },
    "& tr:last-child td": {
      borderBottom: "0px solid darkgrey",
    },
    "& table th": {
      margin: "0px",
      padding: "0.6rem",
      fontSize: "14px",
      borderBottom: "1px solid darkgrey",
      borderRight: "1px solid darkgrey",
      position: "relative",
    },
    "& table td": {
      margin: "0px",
      padding: "0.4rem",
      borderBottom: "1px solid darkgrey",
      borderRight: "1px solid darkgrey",
      position: "relative",
    },
    "& table tr": {
      textAlign: "center",
    },
    "& table th:last-child": {
      borderRight: "0px solid #d1cfc9",
    },
    "& table td:last-child": {
      borderRight: "0px solid #d1cfc9",
    },
  },
  dataError: {
    margin: "5%",
    padding: "5%",
    textAlign: "center",
  },
  pagination: {
    margin: "0px 0px 20px 0px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));
