import { makeStyles } from "@mui/styles";

export function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: "40%",
        display: "flex",
        flexDirection: "column",
        background: 'linear-gradient(0deg, rgba(103, 80, 164, 0.11), rgba(103, 80, 164, 0.11)), #FFFBFE;',
        borderRadius: '28px',
        "@media (max-width: 768px)": {
            width: "65%"
        },
        "@media (max-width: 600px)": {
            width: "90%"
        }
    },
    saunaIdModal: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "10px"
    }
}));
