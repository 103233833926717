import * as React from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import { useMediaQuery, useTheme } from '@mui/material';
import { AccountBox, Group, LocalAtm, Equalizer, Description, Dashboard, Apartment, Edit, Analytics, Devices} from '@mui/icons-material';
import { MyLogoutButton } from '../login';
import { makeStyles } from '@mui/styles';
import { SubMenu } from './subMenu';

export const Menus = (props) => {
    const theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const classes = useStyles();

    const [state, setState] = React.useState({
        dashboard: true,
        factory: false
    });

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Menu {...props} className={classes.menu}>
            <SubMenu handleToggle={() => handleToggle('dashboard')} isOpen={state.dashboard} name={"CcPortal"} icon={<Dashboard />} >
                <MenuItemLink to="/customers" primaryText={'Customers'} leftIcon={<AccountBox />} />
                <MenuItemLink to="/onBoardCustomers" primaryText={'onBoarded Customers'} leftIcon={<Group />} />
                <MenuItemLink to="/sales" primaryText={'Sales'} leftIcon={<LocalAtm />} />
                <MenuItemLink to="/saunaOwnerMaster" primaryText={'Sauna Owner Masters'} leftIcon={<Equalizer />} />
                <MenuItemLink to="/saunaConfiguration" primaryText={'Sauna Configuration'} leftIcon={<Analytics />} />
                <MenuItemLink to="/connectedDevices" primaryText={'Connected Devices'} leftIcon={<Devices />} />
                <MenuItemLink to="/saunaDetails" primaryText={'Sauna Details'} leftIcon={<Description />} />
            </SubMenu>
            <SubMenu handleToggle={() => handleToggle('factory')} isOpen={state.factory} name={"Factory"} icon={<Apartment />} >
                <MenuItemLink to="/createSaunaProduct" primaryText={"Create Sauna Product"} leftIcon={<Edit />} />
                <MenuItemLink to="/saunaProductsData" primaryText={"Sauna Products Data"} leftIcon={<Description />} />
            </SubMenu>
            {isXSmall && <MyLogoutButton />}
        </Menu>
    );
};

const useStyles = makeStyles((theme) => ({
    menu: {
        minHeight: '100%',
        '& a': { paddingTop: '10px', paddingBottom: '10px', marginLeft: '5px', fontSize: '1.09rem' },
        '& svg': { fontSize: '1.5rem' }
    }
}))