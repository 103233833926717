import React, { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const EmployeeLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleLogin = () => {
    setLoading(true);
    const state = { from: "opscenter" };
    window.location.href = `${
      process.env.REACT_APP_AUTH_URL
    }/sunlighten/microsoft_auth?state=${JSON.stringify(state)}`;
  };

  const [emailId, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const login = useLogin();
  const notify = useNotify();

  const submit = (e) => {
    e.preventDefault();
    login({ emailId, firstName, lastName })
      .then((resp) => {
        console.log(resp);
        if (resp.value == "unauthorized") {
          notify("Please enter your official sunlighten email address");
        } else {
          notify("Verfication link has been sent to your mail.");
        }
      })
      .catch(() => notify("failed to connect to the server"));
  };

  return (
    <>
      <Grid container sx={styles.mainGrid}>
        <Grid item lg={4} md={4} sm={10} xs={10}>
          <form onSubmit={submit} className={classes.form}>
            <Grid container sx={styles.loginGrid}>
              <Grid item lg={8} md={8} sm={12} xs={12} sx={styles.heading}>
                <label className={classes.heading}>
                  Sunlighten Login Via Email
                </label>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} sx={styles.text}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  variant="outlined"
                  required
                  name="email"
                  type="email"
                  value={emailId}
                  label="Email"
                  helperText="Official Sunlighten Email Id"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} sx={styles.text}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  variant="outlined"
                  required
                  name="firstName"
                  label="First Name"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} sx={styles.text}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  variant="outlined"
                  required
                  name="lastName"
                  label="Last Name"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} sx={styles.heading}>
                <Button
                  fullWidth
                  variant="contained"
                  size="medium"
                  type="submit"
                  color="warning"
                >
                  Login
                </Button>
                {/* <input className={classes.loginBtn} type="submit" value="Login"></input> */}
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12} sx={styles.orGrid}>
          <div className={classes.horizonStart}></div>
          <div className={classes.horizon}>OR</div>
          <div className={classes.horizonEnd}></div>
        </Grid>
        <Grid item lg={4} md={4} sm={10} xs={10}>
          <Grid container>
            <Grid item xs={12} sx={styles.mLogin}>
              <label className={classes.heading}>
                {" "}
                Sunlighten Employee Login
              </label>
              <Button
                sx={styles.mButton}
                variant="contained"
                size="medium"
                type="submit"
                color="warning"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress size={20} variant="indeterminate" />
                )}
                Login with Sunlighten M365
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Notification />
    </>
  );
};

const styles = {
  mainGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: window.innerHeight,
  },
  loginGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  orGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 965px)": {
      margin: "20px",
      flexDirection: "row",
    },
  },
  mLogin: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mButton: {
    margin: "30px",
    width: "auto",
  },
  heading: {
    margin: "15px",
  },
  text: {
    margin: "5px",
  },
};

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "center",
    paddingTop: "30px",
  },
  heading: {
    fontSize: 22,
    textAlign: "center",
  },
  horizonStart: {
    borderTop: "2px solid #F4772F",
    width: "100px",
    transform: "rotate(90deg)",
    marginBottom: "50px",
    "@media (max-width: 965px)": {
      borderTop: "2px solid #F4772F",
      width: "100px",
      transform: "rotate(0deg)",
      marginBottom: "0px",
    },
  },
  horizonEnd: {
    borderTop: "2px solid #F4772F",
    width: "100px",
    transform: "rotate(90deg)",
    marginTop: "50px",
    "@media (max-width: 965px)": {
      borderTop: "2px solid #F4772F",
      width: "100px",
      transform: "rotate(0deg)",
      marginTop: "0px",
    },
  },
  horizon: {
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
