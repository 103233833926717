import { makeStyles } from "@mui/styles";

export const styles = {
  saunaId: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "1% 5% 0.5% 5%",
    "@media (max-width: 912px)": {
      flexDirection: "column",
    },
  },
  viewButton: {
    width: "10px",
  },

  input: {
    width: "70%",
    "@media (max-width: 912px)": {
      width: "50%",
    },
    "@media (max-width: 550px)": {
      width: "70%",
      margin: "18px 0px 0px 0px",
    },
  },
};

export const useStyles = makeStyles((theme) => ({
  saunaId: {
    width: "35%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "@media (max-width: 912px)": {
      width: "100%",
      margin: "4% 0% 0% 0%",
    },
  },
}));
