import { makeStyles } from "@mui/styles";

export const component = [
    { label: "ACP", value: "ACP" },
    { label: "PCB", value: "PCB" },
    { label: "DCB-1", value: "DCB-1" },
    { label: "DCB-2", value: "DCB-2" },
    { label: "DCB-3", value: "DCB-3" },
    { label: "NIR", value: "NIR" },
    { label: "CD", value: "CD" },
    { label: "SP", value: "SP" },
    { label: "SB100", value: "SB100" },
    { label: "H240F", value: "H240F" },
    { label: "H120FHB", value: "H120FHB" },
    { label: "H240FHB", value: "H240FHB" },
    { label: "H120HH", value: "H120HH" },
    { label: "H240HH", value: "H240HH" },
    { label: "H240HHW", value: "H240HHW" },
    { label: "H120HL", value: "H120HL" },
    { label: "H240HL", value: "H240HL" },
    { label: "H120FH", value: "H120FH" },
    { label: "H240FH", value: "H240FH" },
    { label: "SLCB", value: "SLCB" }
];

export function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

export const styles = {
    input: {
        width: "100%",
        borderRadius: '4px 4px 0px 0px',
        '& .MuiFilledInput-root': {
            backgroundColor: '#E7E0EC'
        }
    },
    photo: {
        margin: '10px',
        width: '50%',
        "@media (max-width: 450px)": {
            width: "100%"
        },
        borderRadius: '16px'
    },
    component: {
        margin: '10px',
        fontSize: '18px',
        width: '50%',
        "@media (max-width: 450px)": {
            width: "100%"
        },
        borderRadius: '16px'
    },
    highlightOff: {
        color: '#49454F',
        cursor: 'pointer'
    },
    camera: {
        color: 'white',
        fontSize: '2.0rem'
    },
    save: {
        color: 'white',
        fontSize: '2.0rem'
    }
}

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '0px',
        position: 'absolute',
        background: 'linear-gradient(0deg, rgba(103, 80, 164, 0.11), rgba(103, 80, 164, 0.11)), #FFFBFE;',
        borderRadius: '28px',
        width: "30%",
        height: 'auto',
        "@media (max-width: 1280px)": {
            width: "40%"
        },
        "@media (max-width: 912px)": {
            width: "55%"
        },
        "@media (max-width: 650px)": {
            width: "90%"
        }
    },
    form: {
        width: '100%'
    },
    select: {
        margin: "20px 50px",
        "@media (max-width: 500px)": {
            margin: "20px 35px"
        },
    },
    saunaIdModal: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "20px 15px",
        "@media (max-width: 500px)": {
            margin: "20px 10px"
        },
    },
    footer: {
        margin: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
