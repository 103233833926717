import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { AppBar, Sidebar, UserMenu, useSidebarState } from 'react-admin';
import { Typography, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MyLogoutButton } from '../login';
import logo from '../SunlightenLogo.png';
import { Menus } from './menu';

const Root = styled("div")(({ theme }) => ({
    width: '100%',
    display: 'flex',
    zIndex: 1,
    position: 'relative',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: 'white'
}));

const AppFrame = styled("div")(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
}));

const ContentWithSidebar = styled("main")(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    marginTop: '65px'
}));

const Content = styled("div")(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: '10px',
    paddingTop: '8px',
    paddingLeft: '5px',
    overflow: 'auto'
}));

export const MyLayout = ({ children }) => {
    const classes = useStyles();
    const [open] = useSidebarState();

    const MyUserMenu = (props) => {
        return (
            <UserMenu {...props}>
                <MyLogoutButton />
            </UserMenu>
        );
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#F4772F",
                contrastText: 'white'
            },
            secondary: {
                main: "#F4772F",
                contrastText: 'white'
            },
            type: "light"
        },
        sidebar: {
            width: 275, // The default value is 240
            closedWidth: 60 // The default value is 55,
        },
        shape: {
            borderRadius: 5
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <Root>
                <AppFrame>
                    <AppBar sx={styles.appbar} open={open} userMenu={<MyUserMenu />} >
                        <Toolbar className={classes.toolBar}>
                            <Typography variant="h6" color="inherit" id="react-admin-title" />
                            <div className={classes.title}>
                                <img src={logo} alt="logo" className={classes.logo} />
                                <Typography variant="h6" color="inherit">Sunlighten - OpsCenter</Typography>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <ContentWithSidebar>
                        <Sidebar sx={styles.sidebar}>
                            <Menus />
                        </Sidebar>
                        <Content>
                            {children}
                        </Content>
                    </ContentWithSidebar>
                </AppFrame>
            </Root>
        </ThemeProvider>
    );
};

MyLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

const styles = {
    appbar: {
        paddingLeft: '0px',
        paddingRight: '0px',
        '& .MuiToolbar-root': {
            minHeight: '56px',
            "@media (max-width: 900px)": {
                paddingRight: '0px',
                paddingLeft: '0px',
                minHeight: '65px'
            }
        }
    },
    sidebar: {
        '& .MuiPaper-root': {
            overflow: 'hidden',
        },
        '& .RaSidebar-fixed': {
            overflow: 'hidden'
        }
    }
}

const useStyles = makeStyles(theme => ({
    toolBar: {
        flex: 1,
        '& span': {
            "@media (max-width: 540px)": {
                marginLeft: '0px'
            }
        }
    },
    color: {
        border: '1px solid white',
        color: theme.palette.background.default,
        '& svg': { color: theme.palette.background.default },
        '& fieldset': { border: 'none' }
    },
    title: {
        flex: 1,
        opacity: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        "@media (max-width: 550px)": {
            opacity: 0,
            width: '1px',
            flexDirection: 'column'
        },
        '& h6': {
            marginLeft: '5px',
            marginRight: '5px',
            "@media (max-width: 550px)": {
                marginTop: '5px',
                fontSize: '18px',
                fontWeight: '600'
            }
        }
    },
    logo: {
        marginLeft: '5px',
        marginRight: '5px',
        width: "40px"
    }
}));