import React from "react";
import { Title } from "react-admin";
import { Grid } from "@mui/material";
import {
  GlobalButton,
  GlobalInput,
  GlobalTable,
  notificationToast
} from "../../GlobalComponents";
import { styles, useStyles } from "./saunaProductsDataStyles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getProductMake } from "../../.././Utilities/saunaIdProductMake";

const { REACT_APP_API_URL } = process.env;

export const SaunaProductsData = () => {
  const [hide, setHide] = React.useState(false);
  const classes = useStyles();
  const [post, setPost] = React.useState("");
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const navigate = useNavigate();
  const ITEMS_PER_PAGE = 10;
  const pageCount = Math.ceil(count / ITEMS_PER_PAGE);

  const onEdit = (id) => {
    return navigate("/productComponentData", { state: { name: id } });
  };

  const COLUMNS = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Serial Number",
      accessor: "serialNumber",
    },
    {
      Header: "Product Name",
      accessor: "productName",
    },
    {
      Header: "View",
      Cell: (tableProps) => (
        <div>
          <GlobalButton
            className={classes.viewButton}
            onClick={() => onEdit(tableProps.row.original.serialNumber)}
          >
            View
          </GlobalButton>
        </div>
      ),
    },
    {
      Header: "Product Make",
      Cell: (tableProps) => (
        <div>
          <label>{getProductMake(tableProps.row.original.productMake)}</label>
        </div>
      ),
    },
    {
      Header: "Product DisplayName",
      accessor: "productDisplayName",
    },

    {
      Header: "Product Description",
      accessor: "productDescription",
    },
  ];

  const saunaData = (e) => {
    setPost(e.target.value);
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentData = async () => {
    try {
      let token = localStorage.getItem('employee_token')
      const start = (currentPage - 1) * ITEMS_PER_PAGE;
      const end = start + ITEMS_PER_PAGE;
      const sort = "id";
      const order = "ASC"
      const config = {
        method: "get",
        url: `${REACT_APP_API_URL}/factory/factoryDetails/getOnlyProductInventoryMaster?search=${post}&_start=${start}&_end=${end}&_sort=${sort}&_order=${order}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await axios(config);
      setData(data.data.value);
      setCount(data.data.count)
      setHide(true);
    } catch (error) {
      setHide(false);
      if (error.response) {
        notificationToast(error.response.data.error);
      } else {
        notificationToast(error.message);
      }
    }
  };

  const onPageChange = (event, value) => changePage(value);

  React.useEffect(() => {
    getCurrentData()
  }, [currentPage, post]);

  return (
    <div>
      <Grid container spacing={2}>
        <Title title={"Sauna Products Data"} />
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={styles.saunaId}>
          <div className={classes.saunaId}>
            <GlobalInput
              size={"small"}
              variant={"outlined"}
              value={post}
              onChange={saunaData}
              sx={styles.input}
              label="Search By Sauna Id"
            />
          </div>
        </Grid>
      </Grid>
      <GlobalTable
        onChange={onPageChange}
        page={currentPage}
        count={pageCount}
        columns={COLUMNS}
        data={data}
        visible={hide}
        error={"Data not present for that SaunaId"}
      />
    </div>
  );
};
