import { makeStyles } from "@mui/styles";

export const saunaModels = [
  { label: "", value: 0 },
  { label: "Aspire", value: 1 },
  { label: "Believe", value: 2 },
  { label: "Conquer", value: 3 },
  { label: "Discover", value: 4 },
  { label: "Empower", value: 5 },
];

export const products = [
  { label: "Mpulse", value: "Mpulse" },
  { label: "Solo", value: "Solo" },
  { label: "Solo Pad", value: "Solo Pad" },
  { label: "Solo Rise", value: "Solo Rise" },
  { label: "Signature", value: "Signature" },
  { label: "Amplify", value: "Amplify" },
];

export const styles = {
  grid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "@media (max-width: 599px)": {
      justifyContent: "center",
    },
  },
  gridItem: {
    margin: "0px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 599px)": {
      margin: "20px 0px 0px 0px",
    },
  },
  highlightOff: {
    color: "#49454F",
    cursor: "pointer",
  },
  input: {
    width: "100%",
    borderRadius: "4px 4px 0px 0px",
    "& .MuiFilledInput-root": {
      backgroundColor: "#E7E0EC",
    },
  },
  button: {
    padding: "10px 24px",
    gap: "10px",
    width: "106px",
    height: "40px",
    background: "#F2994A",
    borderRadius: "100px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.1px",
    color: "#381E72",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
};

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    "@media (max-width: 599px)": {
      width: "75%",
    },
    "@media (max-width: 400px)": {
      width: "90%",
    },
  },
  select: {
    margin: "25px 0px 25px 0px",
  },
  selectButton: {
    margin: "25px 0px 25px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      width: "60%",
    },
  },
  button: {
    margin: "10px 0px 10px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  topbar: {
    top: "90px",
    right: "5px",
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    alignItems: "center",
    background: "#F4772F",
    padding: "8px",
    borderRadius: "50px",
    "& svg": {
      color: "white",
      "@media (max-width: 350px)": {
        fontSize: "1.25rem",
      },
    },
    "@media (min-width: 401px)": {
      right: "20px",
    },
  },
}));
