import React from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { LineChart } from "../../components/chart";
import { CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GlobalButton } from "../../../Factory/GlobalComponents";

Chart.register(CategoryScale);

export const LineChartPage = ({ channelSubscribe, payloadChartData, programname, payloadDuration }) => {
    const classes = useStyles();
    const [toggle, setToggle] = React.useState(false);

    const toggleTempUnit = () => {
        setToggle(!toggle)
    }

    const convertTemp = (temp) => {
        return toggle ? temp.map(item => Math.round((item * (9 / 5)) + 32)) : temp.map(item => item)
    }

    const chartData = {
        labels: payloadDuration?.map((data) => data),
        datasets: [
            {
                label: `Cabin Temparature`,
                data: convertTemp(payloadChartData?.map((data) => data?.msg?.state?.CabinTemperature)),
                fill: false,
                backgroundColor: ["#f4f6fa"],
                borderColor: "#2fb344",
                borderWidth: 1.5
            }
        ]
    }

    return (
        channelSubscribe === true && payloadChartData.length > 0 && programname !== "" &&
        <CardContent className={classes.cardContentMqtt}>
            <div className={classes.cardContentMqttDiv}>
                <label className={classes.label}>{programname}</label>
                <GlobalButton onClick={toggleTempUnit}>Temp {toggle ? "°F" : "°C"}</GlobalButton>
            </div>
            <LineChart chartData={chartData} />
        </CardContent>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContentMqtt: {
        margin: '10px',
        border: '2px solid lightgrey',
        borderRadius: '5px',
        width: '50%',
        "@media (max-width: 820px)": {
            display: 'flex',
            width: "auto",
            flexDirection: 'column'
        },
        "@media (max-width: 300px)": {
            padding: '10px'
        }
    },
    cardContentMqttDiv: {
        margin: '10px',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center'
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        color: 'grey',
        fontWeight: 600
    },
}));