import * as React from "react";
import { Title } from "react-admin";
import { Grid, InputAdornment } from "@mui/material";
import { HighlightOff, CalendarMonth } from "@mui/icons-material";
import { GlobalButton, GlobalInput, GlobalSelect, notificationSuccessToast, notificationToast } from "../../GlobalComponents";
import { styles, useStyles, saunaModels, products } from './createSaunaProductsStyles'
import { useNavigate } from "react-router-dom";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const CreateSaunaProducts = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [productName, setProductName] = React.useState('');
    const [model, setModel] = React.useState('');
    const [productMake, setProductMake] = React.useState('');
    const [serialNumber, setSerialNumber] = React.useState('');
    const [productDescription, setProductDescription] = React.useState('');
    const [saunaId, setSaunaId] = React.useState('');

    const handleModelChange = (event) => {
        setProductMake(event.target.value);
        setModel(event.target.value);
    };

    async function generateSaunaId(id) {
        try {
            let token = localStorage.getItem('employee_token')
            const response = await axios.post(`${REACT_APP_API_URL}/factory/generateSaunaId`, { modalNumber: id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });
            if (response.data.result) {
                setSaunaId(response.data.value.SaunaId);
                setSerialNumber(response.data.value.SaunaId)
                setModel(saunaModels[0].value);
            } else {
                notificationToast("Please Select Different Model Number");
            }
        } catch (error) {
            if (error.response) {
                notificationToast(error.response.data.error);
            } else {
                notificationToast(error.message);
            }
        }
    }

    const generatedSaunaId = () => {
        if (model !== '') {
            return generateSaunaId(model)
        } else {
            return generateSaunaId(productMake)
        }
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        let token = localStorage.getItem('employee_token')
        const data = {
            serialNumber: serialNumber.toLowerCase(),
            productName: productName,
            productMake: productMake,
            productDescription: productDescription,
            productDisplayName: productName
        };
        const config = {
            method: 'post',
            url: `${REACT_APP_API_URL}/factory/factoryDetails/addNewProductInventoryMaster`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        await axios(config).then(function (response) {
            response.data.result === true ? notificationSuccessToast("Product Data is Submitted Sucessfully") : notificationToast(response.data.error)
            setProductMake('');
            setSerialNumber('');
            setProductName('');
            setProductDescription('');
            return navigate("/createSaunaComponent", { state: { name: saunaId } })
        }).catch(function (error) {
            if (error.response) {
                notificationToast(error.response.data.error);
            } else {
                notificationToast(error.message);
            }
        });
    }

    return (
        <div>
            <Title title={"Create Sauna Product"} />
            <div className={classes.topbar}>
                <CalendarMonth />
            </div>
            <Grid container spacing={2} sx={styles.grid}>
                <Grid item xs={10} sm={6} md={5} lg={4} xl={4} sx={styles.gridItem}>
                    <form onSubmit={onFormSubmit} className={classes.form} autoComplete="off">
                        <div className={classes.select}>
                            <GlobalSelect
                                required={true}
                                variant={'outlined'}
                                options={products}
                                value={productName}
                                label={"Select Product"}
                                inputLabel={"Select Products"}
                                handleChange={(event) => { setProductName(event.target.value) }}
                            />
                        </div>
                        <div className={classes.select}>
                            <GlobalSelect
                                required={true}
                                variant={'outlined'}
                                options={saunaModels}
                                value={productMake}
                                label={"Select Model"}
                                inputLabel={"Select Model"}
                                handleChange={handleModelChange}
                            />
                        </div>
                        <div className={classes.select}>
                            <GlobalInput
                                required={true}
                                size={'small'}
                                variant={"filled"}
                                value={serialNumber}
                                sx={styles.input}
                                label="Product Serial Number"
                                helperText={"Generate Serial Number"}
                            />
                        </div>
                        <div className={classes.button}>
                            <GlobalButton sx={styles.button} disabled={productMake === ''} onClick={generatedSaunaId} >
                                Generate
                            </GlobalButton>
                        </div>
                        <div className={classes.select}>
                            <GlobalInput
                                multiline
                                maxRows={3}
                                InputProps={{ endAdornment: <InputAdornment position="end"><HighlightOff sx={styles.highlightOff} onClick={() => setProductDescription('')} /></InputAdornment> }}
                                size={'small'}
                                variant={"outlined"}
                                required={false}
                                value={productDescription}
                                onChange={(e) => { setProductDescription(e.target.value) }}
                                sx={styles.input}
                                label="Description"
                            />
                        </div>
                        <div className={classes.selectButton}>
                            <GlobalButton type="submit">
                                Next
                            </GlobalButton>
                        </div>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
};
