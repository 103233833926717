import React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { PowerSettingsNew } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

export const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const classes = useStyles();

    const handleClick = async () => {
        const token = localStorage.getItem('employee_token')
        const encodedToken = encodeURIComponent(token)
        window.location.href = await `${process.env.REACT_APP_AUTH_URL}/sunlighten/microsoft_auth/logout?token=${encodedToken}`
        localStorage.clear()

        return logout();
    }

    return (
        <MenuItem onClick={handleClick} ref={ref} >
            <ListItemIcon className={classes.listIcon}>
                <PowerSettingsNew />
                <ListItemText className={classes.listText}>Logout</ListItemText>
            </ListItemIcon>
        </MenuItem>
    )
});

const useStyles = makeStyles((theme) => ({
    listIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listText: {
        marginLeft: 14
    }
}))