import * as React from 'react';
import { useSidebarState } from 'react-admin';
import { List, MenuItem, ListItemIcon, Collapse, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const SubMenu = (props) => {
    const { handleToggle, isOpen, name, children, icon } = props;
    const classes = useStyles();
    const [sidebarIsOpen] = useSidebarState();

    const header = (
        <MenuItem onClick={handleToggle} className={classes.listItemText}>
            <ListItemIcon sx={styles.listItemIcon} >
                {isOpen ? icon : icon}
                <Typography>{name}</Typography>
            </ListItemIcon>
            <ListItemIcon>
                {isOpen ? (
                    <>
                        {sidebarIsOpen ? <ExpandLess /> : null}
                    </>
                ) : (
                    <>
                        {sidebarIsOpen ? <ExpandMore /> : null}
                    </>)}
            </ListItemIcon>
        </MenuItem >
    );

    return (
        <div className={classes.subMenu}>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        '& a': {
                            transition:
                                'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            paddingLeft: sidebarIsOpen ? 4 : 2,
                        },
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </div>
    );
};

const styles = {
    listItemIcon: {
        minWidth: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

const useStyles = makeStyles(theme => ({
    subMenu: {
        '& li': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '10px',
            paddingBottom: '10px',
            marginTop: '5px',
            marginBottom: '5px',
            '& svg': { fontSize: '1.9rem' },
        },
        '& span': { fontSize: '1.25rem' },
    },
    listItemText: {
        '& p': {
            marginLeft: '15px',
            color: 'rgba(0, 0, 0, 0.6)',
        }
    }
}));