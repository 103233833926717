var jwt = require("jsonwebtoken");
const { REACT_APP_AUTH_URL } = process.env;

export const authProvider = {
  login: async ({ emailId, firstName, lastName }) => {
    const state = { from: 'opscenter' }
    const request = new Request(
      `${REACT_APP_AUTH_URL}/sunlighten/login?state=${JSON.stringify(state)}`,
      {
        method: "POST",
        body: JSON.stringify({ emailId, firstName, lastName }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        return auth;
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("employee_auth_info");
      localStorage.removeItem("employee_token");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: async () => {
    if (localStorage.getItem("employee_token")) {
      return Promise.resolve();
    } else {
      try {
        let Hashed_URL = window.location.href.replace("#/", "");
        const CURRENT_URL = new URL(Hashed_URL);
        const urlParams = new URLSearchParams(CURRENT_URL.search);
        const token = urlParams.get("token");
        const decoded = jwt.decode(token, { verify: false });
        if (decoded.data.emailId) {
          localStorage.setItem("employee_auth_info", JSON.stringify(decoded));
          localStorage.setItem("employee_token", token);
          return Promise.resolve();
        } else {
          console.log("rejected");
          return Promise.reject({ message: false });
        }
      } catch (err) {
        console.log(err, "check auth");
        return Promise.reject({ message: false });
      }
    }
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};
