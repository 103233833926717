import React from "react";
import {
  notificationToast,
  notificationSuccessToast,
  GlobalTable,
  GlobalButton,
} from "../../GlobalComponents";
import { useStyles } from "./productComponentStyles";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Title } from "react-admin";
import { CreateProductModal } from "../../components/CreateProductModal/createProductModal";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { getProductMake } from "../../.././Utilities/saunaIdProductMake";

const { REACT_APP_API_URL } = process.env;

export const ProductComponentData = () => {
  const [hide, setHide] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [componentData, setComponentData] = React.useState([]);
  const location = useLocation();
  const saunaId = location.state.name;
  const navigate = useNavigate();
  const [posts, setPosts] = React.useState([]);
  const [productName, setProductName] = React.useState("");
  const [productDescription, setProductDescription] = React.useState("");

  const handleOpenModal = () => {
    setOpenModal(true);
    setProductName(data?.productName)
    setProductDescription(data?.productDescription)
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleProductChange = (event) => {
    setProductName(event.target.value);
  };

  const onProductDescriptionChange = (e) => {
    setProductDescription(e.target.value);
  };

  const onProductDescriptionClose = (e) => {
    setProductDescription("");
  };

  const imageKeys = async () => {
    let token = localStorage.getItem('employee_token')
    const config = {
      method: "get",
      url: `${REACT_APP_API_URL}/factory/imageUpload/imageList?key=${saunaId.toLowerCase() + "/"
        }`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then(function (response) {
        const keys = response.data.value;
        photos(keys);
      })
      .catch(function (error) {
        if (error.response) {
          notificationToast(error.response.data.error);
        } else {
          notificationToast(error.message);
        }
      });
  };

  async function photos(keys) {
    try {
      const imageKeys = keys.map((item) =>
        axios.get(`${REACT_APP_API_URL}/factory/imageUpload/image?key=${item}`)
      );
      const items = await Promise.all(imageKeys);
      setPosts(items);
    } catch (error) {
      notificationToast(error.message);
    }
  }

  const OnEdit = (id) => {
    return navigate("/createSaunaComponent", { state: { name: id } });
  };

  const COLUMNS = [
    {
      Header: "Serial Number",
      accessor: "serialNumber",
    },
    {
      Header: "Component Serial Number",
      accessor: "componentSerialNumber",
    },
    {
      Header: "Component Type",
      accessor: "componentType",
    },
    {
      Header: "Image",
      accessor: "imagePath",
      Cell: (tableProps) => (
        <div>
          <img
            width={40}
            height={40}
            src={`${REACT_APP_API_URL}/factory/imageUpload/image?key=${tableProps.row.original.imagePath}`}
          />
        </div>
      ),
    },
  ];

  const productInventoryMaster = async () => {
    try {
      let token = localStorage.getItem('employee_token')
      const config = {
        method: "get",
        url: `${REACT_APP_API_URL}/factory/factoryDetails/getProductInventoryMaster?search=${saunaId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const data = await axios(config);
      setData(data.data.value[0]);
      setComponentData(
        data.data.value && data.data.value[0].ComponentInventoryMasterDetail
      );
      setHide(true);
    } catch (error) {
      setHide(false);
      if (error.response) {
        notificationToast(error.response.data.error);
      } else {
        notificationToast(error.message);
      }
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem("employee_token");
    const data = {
      serialNumber: saunaId.toLowerCase(),
      productName: productName,
      productDescription: productDescription,
      productDisplayName: productName,
    };
    const config = {
      method: "post",
      url: `${REACT_APP_API_URL}/factory/factoryDetails/updateProductInventoryMaster`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        response.data.result === true
          ? notificationSuccessToast("Product Data is Submitted Sucessfully")
          : notificationToast(response.data.error);
        setProductName("");
        setProductDescription("");
        productInventoryMaster();
        setOpenModal(false);
      })
      .catch(function (error) {
        if (error.response) {
          notificationToast(error.response.data.error);
        } else {
          notificationToast(error.message);
        }
      });
  };

  React.useEffect(() => {
    imageKeys();
    productInventoryMaster();
  }, [saunaId]);

  return (
    <div className={classes.mainDiv}>
      <Title title="Product Component Details" />
      <div className={classes.productData}>
        <div className={classes.details}>
          <h4 className={classes.heading}>PRODUCT DETAILS</h4>
          <div className={classes.editButton}>
            <GlobalButton onClick={handleOpenModal} >Edit</GlobalButton>
          </div>
        </div>
        <div className={classes.product}>
          <div>
            <h4 className={classes.text}>serialNumber</h4>
            <h4 className={classes.text}>ProductName</h4>
            <h4 className={classes.text}>productDisplayName</h4>
            <h4 className={classes.text}>productMake</h4>
            <h4 className={classes.text}>productDescription</h4>
          </div>
          <div className={classes.partition}>
            <h4 className={classes.textData}>
              :<span style={{ marginLeft: "10px" }}>{data?.serialNumber}</span>
            </h4>
            <h4 className={classes.textData}>
              :<span style={{ marginLeft: "10px" }}>{data?.productName}</span>
            </h4>
            <h4 className={classes.textData}>
              :
              <span style={{ marginLeft: "10px" }}>
                {data?.productDisplayName}
              </span>
            </h4>
            <h4 className={classes.textData}>
              :
              <span style={{ marginLeft: "10px" }}>
                {getProductMake(data?.productMake)}
              </span>
            </h4>
            <h4 className={classes.textData}>
              :
              <span style={{ marginLeft: "10px" }}>
                {data?.productDescription}
              </span>
            </h4>
          </div>
        </div>
        <div className={classes.v1}></div>
      </div>
      <div className={classes.table}>
        <div className={classes.details}>
          <h4>COMPONENT DETAILS</h4>
          <div className={classes.editButton}>
            <GlobalButton onClick={() => OnEdit(saunaId)}>Edit</GlobalButton>
          </div>
        </div>
        <GlobalTable
          columns={COLUMNS}
          data={componentData}
          visible={hide}
          error={"Data not present for this SaunaId"}
        />
      </div>
      <div className={classes.table}>
        <div className={classes.details}>
          <h4>QC PICS</h4>
          <div className={classes.editButton}>
            <GlobalButton onClick={() => OnEdit(saunaId)}>Edit</GlobalButton>
          </div>
        </div>
        <div>
          {posts.length !== 0 ? (
            <div className={classes.cardComponentQc}>
              {posts.map((items, index) => {
                return (
                  <div key={index}>
                    <img
                      src={items?.config?.url}
                      className={classes.imageQc}
                      alt="img"
                    />
                    <div className={classes.component}></div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.dataError}>
              <Typography>No Qc Pics Found For This SAUNAID</Typography>
            </div>
          )}
        </div>
      </div>
      <div>
        <CreateProductModal
          saunaId={saunaId}
          productDescription={productDescription}
          productName={productName}
          handleProductChange={handleProductChange}
          onProductDescriptionChange={onProductDescriptionChange}
          onProductDescriptionClose={onProductDescriptionClose}
          onSubmit={onFormSubmit}
          open={openModal}
          onClose={handleCloseModal}
        />
      </div>
    </div>
  );
};
